<template>
  <el-popover placement="bottom" width="276" trigger="manual" v-model="isShow">
    <div class="collection_box">
      <div class="title">
        <span>选择收藏夹</span>
        <i @click="onCloseCollection" class="el-icon-close close_icon"></i>
      </div>
      <ul class="collection_list">
        <li @click="onSelectfolder(item.folderName)" v-for="item in  folderList" :key="item.id">
          <div>
            <i class="iconfont  icon-a-bianzubeifen15"></i>
            <span>{{item.folderName}}</span>
          </div>
        </li>
      </ul>
      <div class="btn_box">
        <div class="add_folder_box">
          <i @click="isInput = true" class="el-icon-circle-plus"></i>
          <span @click="isInput = true">新建文件夹</span>
        </div>
        <div class="input_box">
          <el-input size="mini" placeholder="请输入文件夹名字" v-model="folderName">
            <template slot="append">
              <div @click="onAddFolder" class="add_btn">新建</div>
            </template>
          </el-input>
        </div>
      </div>
    </div>

    <slot>
      <div slot="reference" class=" hand collect_btn" @click.stop="collect">
        <i class="iconfont icon-a-shoucangshoucangqianicon"></i>
        收藏
      </div>
    </slot>
  </el-popover>
</template>
<script>
import { getFolderList, addFolder, addCollectToFolder } from '@/api/user.js'
import { mapGetters } from 'vuex'
export default {
  props: {
    itemId: {
      type: String,
      default: ''
    },
    category: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      folderList: [],
      isInput: false, // 新增输入框状态
      folderName: '',
      isShow: false
    }
  },
  methods: {
    collect () {
      if (!this.userId) {
        return this.$login()
      }
      if (this.category === 1) {
        this.$myCount({
          name: 'PC-文献收藏',
          itemId: this.itemId
        })
      }
      this.isShow = true
    },
    async onSelectfolder (name) {
      try {
        await addCollectToFolder({
          itemId: this.itemId,
          category: this.category,
          folderName: name
        })
        this.$message.success(`已成功收藏至 : ${name} 文件夹`)
        this.onCloseCollection()
        this.$emit('upDataType')
      } catch (error) {
        this.$message.error('收藏失败')
      }
    },
    // 新增文件夹
    async onAddFolder () {
      try {
        if (/^\s+$/g.test(this.folderName)) return this.$message.warning('文件夹名字不可为空')
        if (this.folderList.length >= 10) return this.$message.warning('最多只可以创建10个文件夹')
        if (!/^[a-z0-9\u4e00-\u9fa5]+$/i.test(this.folderName)) return this.$message.warning('只可以输入中文英文和数字')
        if (this.folderName.length > 10) return this.$message.warning('文件夹名字长度不可超过10个字符')
        if (this.isFolderName(this.folderName)) return this.$message.warning('文件夹名字已存在')
        await addFolder({ folderName: this.folderName })
        this.$message.success('创建成功')
        this.isInput = false
        this.folderName = ''
        this.getFolderList()
      } catch (error) {
        this.$message.error('创建失败')
      }
    },
    // 判断文件名字是否重复
    isFolderName (name) {
      return this.folderList.find(item => (item.folderName === name))
    },
    // 获取文件夹列表
    async getFolderList () {
      const res = await getFolderList()
      this.folderList = res.data
    },
    // 关闭弹窗
    onCloseCollection () {
      this.isShow = false
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  watch: {
    isInput (newVal) {
      const inputBox = document.querySelector('.input_box')
      if (newVal) {
        inputBox.style.bottom = '0px'
      } else {
        inputBox.style.bottom = '-60px'
      }
    }
  },
  created () {
    this.getFolderList()
    this.isInput = false
    this.folderName = ''
  }
}
</script>
<style lang="less" scoped>
.collect_btn {
  position: relative;
  top: 0;
  display: inline-block;
  width: 90px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 5px;
  margin: 0 30px 0px 0;
  font-size: 14px;
  color: #4b639f;
  border: 1px solid #4b639f;
  &:hover {
    top: -2px;
    transition: all 0.3s;
  }
}
.collection_box {
  padding-top: 20px;
}

.title {
  position: relative;
  height: 41px;
  width: 100%;
  padding: 0 20px;
  color: #999;
  .close_icon {
    position: absolute;
    top: -7px;
    right: 10px;
    padding: 3px;
    border-radius: 50%;
    background-color: #e8e8e8;
    transform: rotate(0deg);
    transition: transform 0.3s;
    &:hover {
      transform: rotate(180deg);
    }
  }
}
.collection_list {
  width: 100%;
  height: 310px;
  padding: 0 20px;
  border-bottom: 1px solid #e8e8e8;
  overflow-y: auto;
  &::-webkit-scrollbar {
    /* 滚动条容器 */
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    /* 滚动条手柄 */
    /* background-color: #00adb5; */
    background-color: #c1c1c1;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    /*滚动条的轨道*/
    border-radius: 3px;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    padding: 0 20px 0 10px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    &:last-child {
      border: 0;
    }
    &:hover {
      background-color: #e8e8e8;
    }
    .iconfont {
      margin-right: 10px;
      color: #f1c763;
    }
  }
}

.btn_box {
  position: relative;
  height: 60px;
  overflow: hidden;
}

.add_folder_box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  .el-icon-circle-plus {
    cursor: pointer;
    color: #f1c763;
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
  }
  span {
    cursor: pointer;
  }
}
.input_box {
  position: absolute;
  bottom: -60px;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  transition: bottom 0.3s;
  /deep/.el-input__inner {
    border-color: #dcdfe6;
  }
  /deep/.el-input__inner:focus {
    border-color: #dcdfe6;
  }
  /deep/.el-input-group__append {
    border-color: #dcdfe6;
    color: #4b639f;
    padding: 0;
    .add_btn {
      width: 100%;
      height: 100%;
      padding: 4px 20px;
      cursor: pointer;
    }
  }
}
</style>
