<template>
  <!-- 全局搜索框(除主页) 组件 -->
  <div class="loginTitle">
    <!-- 全局title搜索框 -->
    <div class="login_search">
      <div class="search two" @click.stop>
        <el-input placeholder="输入中英文关键词/英文标题/DOI/PMID" v-model="input" @keyup.enter.native="search"
                  clearable @focus="onOpenSearchProposal" @input="inputValChange" size="mini">
          <i slot="prefix" class="searchIcon">
            <img src="@/assets/img/index/searchIcon.png">
          </i>
          <el-button class="search_btn" slot="append" @click="search">Search</el-button>
        </el-input>
        <!-- 搜索建议框 -->
        <div v-show="isSearchProposal" class="search_proposal_box">
          <searchProposal :inputVal="input" @valChange="valChange" />
        </div>
        <span class="highSearch" @click="onGotoAdvanSearch">高级搜索</span>
      </div>
    </div>
    <!-- 点击高级搜索  引入遮罩层
    <keep-alive>
      <advanced-search v-on="$listeners" ref="advancedSearch" :showBox.sync="showBox" @clearVal="clearVal" />
    </keep-alive> -->
  </div>
</template>
<script>
import { getHistory, setHistory } from '@/utils/storage.js'
import searchProposal from '@/components/searchProposal/index.vue'
export default {
  props: {
    val: {
      type: String,
      default: ''
    }
  },
  components: {
    searchProposal
  },
  data () {
    return {
      input: '', // 输入框内容
      isSearchProposal: false // 搜索建议状态
    }
  },
  methods: {
    // 搜索框值变化
    inputValChange () {
      if (this.input) {
        this.isSearchProposal = false
      } else {
        this.isSearchProposal = true
      }
    },
    // 关闭搜索建议
    hideBox () {
      this.isSearchProposal = false
    },
    // 输入框获取焦点
    onOpenSearchProposal () {
      // 后期有搜索建议要改
      if (!this.input) {
        this.isSearchProposal = true
      }
    },
    // 搜索建议框值改变
    valChange (keyword) {
      this.isSearchProposal = false
      this.input = keyword
      this.search()
    },
    // 清空输入框的值
    clearVal () {
      this.input = ''
    },
    // 打开高级搜索盒子
    onGotoAdvanSearch () {
      this.isSearchProposal = false
      this.$router.push('/advanceSearch')
    },
    // 搜索按钮
    search () {
      const reg = /^\s+$/g
      const isInput = reg.test(this.input)
      if (this.input === '' || isInput) return this.$message.warning('搜索内容不可为空!')
      this.$emit('Jump', this.input)
      // 保存到本地存储
      const arr = JSON.parse(getHistory() || '[]')
      arr.unshift(this.input)
      setHistory([...new Set(arr)])
      // 搜索完毕后重置输入框
      // this.input = ''
    }
  },
  created () {
    // 判断不是通过按钮入口过来的再让input回显数据
    if (this.$route.path === '/searchList' && this.$route.query.val !== 'index') {
      this.input = this.$route.query.val
    }
    if (this.$route.query.val === 'index' && !this.input) {
      this.openBox()
    }
  }
}
</script>
<style scoped lang="less">
.loginTitle {
  width: 100%;
  color: #4b639f;
  background-color: #f5f7fb;
  padding: 20px 0 0px;
  .login_search {
    width: 1200px;
    margin: 0 auto;
    padding-left: 146px;
    .loginTile {
      box-shadow: none;
    }
    .search_logo {
      font-size: 50px;
      font-weight: 600;
      margin: 50px 0 0;
    }
    .search_title {
      margin: 10px 0;
    }
    .two {
      margin: 0 auto !important;
    }
    .search {
      position: relative;
      width: 77.3%;
      margin: 20px auto 25px;
      border-radius: 50px;
      box-shadow: 0px 0px 0px 0px #000;
      display: flex;
      align-items: center;
      .searchIcon {
        img {
          width: 18px;
          height: 18px;
        }
        margin-left: 20px;
      }
      .search_btn {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
      }
      /deep/ .el-input__prefix {
        top: 13px;
        left: 0px;
      }
      /deep/ .el-input-group {
        display: flex;
      }
      /deep/ .el-input__inner {
        height: 40px;
        box-sizing: content-box;
        padding: 0 20px 0 50px;
        line-height: 40px;
        font-size: 14px;
        color: #4b639f;
        border: 1px solid #cdcdcd;
        background-color: transparent;
      }
      /deep/.el-input__inner {
        &::placeholder {
          font-size: 14px;
          line-height: 40px;
        }
      }
      /deep/ .el-input-group__append {
        background-color: #4b639f;
        border: 1px solid #4b639f;
        border-left: none;
        box-sizing: border-box;
        height: 42px;
        width: 82px;
        display: grid;
        align-items: center;
        justify-content: center;
      }
      .el-input {
        height: 42px;
        border-radius: 2px;
      }
      .highSearch {
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin: 0 0 0 30px;
        border-radius: 2px;
        font-weight: 600;
        color: #4b639f;
        cursor: pointer;
        &:hover {
          // margin-top: -2px;
          background-color: #4b639f;
          color: #fff;
          box-shadow: inset 4px 5px 4px 0px rgba(255, 255, 255, 0.3);
          transition: all 0.7s;
        }
      }
      // 搜索建议
      .search_proposal_box {
        position: absolute;
        left: 0;
        top: 55px;
        width: calc(100% - 146px);
        min-height: 200px;
        z-index: 101;
      }
    }
  }
}
</style>
