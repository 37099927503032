<template>
  <div v-if="isShow" class="my_card is_blank" :style="{marginTop: marginTop}">
    <i class="el-icon-warning-outline"></i>
    {{content}}
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: '暂无内容'
    },
    marginTop: {
      type: String,
      default: '0px'
    }
  },
  data () {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.is_blank {
  width: 100%;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  color: #a7a7ab;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
