<template>
  <MyDialog ref="loginDialog" :isShow.sync="isLogin" width="730px" :height="'478px'" :isBg="true"
            :isTitle="false" zIndex="3000" @handleClose="handleClose" bg="rgba(0, 0, 0, 0.1)">

    <div class="login_content">
      <div class="content">
        <div class="head_box">
          <i class="el-icon-close close_icon hand" @click="closeLogin"></i>
        </div>
        <div v-show="!isSelectEnterprise">
          <div class="login" v-show="!isRegister && !isEditPassword">
            <div class="tabs_box no_select">
              <el-tabs v-model="activeComponentName">
                <el-tab-pane v-for="tab in tabs" :key="tab.label" :label="tab.label"
                             :name="tab.componentName"></el-tab-pane>
              </el-tabs>
              <p class="tac login_warn" v-if="loginWarn">{{loginWarn}}</p>
            </div>

            <keep-alive include='ShortMsg'>
              <component ref="loginComponent" :is='activeComponentName' @toRegister="toRegister"
                         :isLogin="isLogin" @select="select" @toUpdataPass="toUpdataPass">
              </component>
            </keep-alive>

          </div>
          <Register v-if="isRegister && !isEditPassword" @toLogin="toLogin" />
          <EditPassword v-if="!isRegister && isEditPassword" @toLogin="toLogin"
                        @toRegister="toRegister" />
        </div>
        <div class="ip_info" v-if="ipInfo.partnerId">
          <!-- 当前IP: {{ipInfo.requestIp}} 已覆盖VIP权益 -->
          【{{ipInfo.partnerName}}】已获IP认证，登录使用权益
        </div>
        <EnterpriseList v-if="isSelectEnterprise" @resetLogin="resetLogin" ref="enterpriseList" />
      </div>

    </div>
  </MyDialog>
</template>

<script>
import Vue from 'vue'
import MyDialog from '@/components/myDialog/newIndex.vue'
import QrCode from './components/qrCode.vue'
import ShortMsg from './components/shortMsg.vue'
import Password from './components/password.vue'
import Register from './components/register.vue'
import EditPassword from './components/editPassword.vue'
import EnterpriseList from './components/enterpriseList.vue'

export default {
  props: {},
  components: {
    MyDialog,
    QrCode,
    ShortMsg,
    Password,
    Register,
    EditPassword,
    EnterpriseList
  },
  data () {
    return {
      isLogin: false,
      isRegister: false,
      isEditPassword: false,
      isSelectEnterprise: false,
      activeComponentName: 'QrCode',
      loginWarn: '',
      tabs: [
        {
          label: '快捷登录',
          componentName: 'QrCode'
        }, {
          label: '短信登录',
          componentName: 'ShortMsg'
        }, {
          label: '密码登录',
          componentName: 'Password'
        }
      ]
    }
  },
  methods: {
    toUpdataPass () {
      this.isEditPassword = true
    },
    resetLogin (componentName = 'QrCode') {
      this.isSelectEnterprise = false
      this.activeComponentName = componentName
      if (componentName === 'ShortMsg') {
        this.$refs.loginComponent.init && this.$refs.loginComponent.init()
      }
    },
    select (list) {
      this.isSelectEnterprise = true
      this.$nextTick(() => {
        this.$refs.enterpriseList.open(list)
      })
    },
    toLogin (componentName = 'QrCode') {
      this.isEditPassword = false
      this.isRegister = false
      this.activeComponentName = 'QrCode'
      this.$nextTick(() => {
        this.activeComponentName = componentName
      })
    },
    handleClose () {
      this.isRegister = false
      this.loginWarn = ''
      this.isEditPassword = false
      this.isSelectEnterprise = false
      if (this.activeComponentName === 'QrCode') {
        this.$refs.loginComponent.close()
      }
    },
    toRegister () {
      this.isRegister = true
      this.isEditPassword = false
      if (this.activeComponentName === 'QrCode') {
        this.activeComponentName = 'ShortMsg'
      }
    },
    closeLogin () {
      this.$refs.loginDialog.closeDialog()
    },
    async openLogin (config = {}) {
      this.isLogin = true
      if (config.type && config.type === 'register') {
        this.toRegister()
      } else {
        this.activeComponentName = config?.activeComponentName || 'QrCode'
        if (this.activeComponentName === 'QrCode') {
          this.$refs.loginComponent.init && this.$refs.loginComponent.init()
        }
      }
      if (config.loginWarning) (this.loginWarn = config.loginWarning)
      this.$store.dispatch('user/getUserIpInfo')
    }
  },
  computed: {
    ipInfo () {
      return this.$store.getters.userIpInfo
    }
  },
  created () {
    Vue.prototype.$login = (config) => this.openLogin(config)
    Vue.prototype.$closeLogin = () => this.closeLogin()
  }
}
</script>

<style lang="less" scoped>
/deep/ .dialog_boby {
  background-color: transparent;
}

.login_content {
  display: grid;
  grid-template-columns: 330px 1fr;
  min-height: 478px;
  width: 100%;
  background: url('https://img.infox-med.com/webImg/infoPc/login/login_bg.png')
    no-repeat;
  background-size: 100% calc(100% - 10px);
  background-position: 0 10px;
  .content {
    position: relative;
    grid-column-start: 2;
    grid-column-end: 3;
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-bottom: 10px;
  }
}
.ip_info {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 45px;
  padding: 14px 15px 0 25px;
  font-weight: 600;
  color: #fff;
  background: url('https://img.infox-med.com/webImg/infoPc/icon/ip_vip_bg.png')
    no-repeat;
  background-size: 100%;
}
.head_box {
  padding: 15px;
  text-align: end;
  .close_icon {
    font-size: 16px;
    color: #000;
    transition: all 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}
.bg_login_box {
  width: 100%;
}
/deep/.tabs_box {
  position: relative;
  margin-bottom: 15px;
  padding-left: 40px;
  .el-tabs__item {
    color: #000;
    font-size: 18px;
  }
  .is-active {
    color: #000;
    font-weight: 600;
  }
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__nav-wrap::after {
    width: 0;
    height: 0;
  }
  .el-tabs__active-bar {
    left: 20px;
    height: 3px;
    bottom: 3px;
    width: 30px !important;
  }
  .login_warn {
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    color: #fa6064;
  }
}
/deep/.dialog_boby {
  overflow: visible;
}
</style>
