<template>
  <div class="identity_container no_select">
    <div class="identity_list">
      <div class="identity_item hand" @click="changeIdentity(item)" v-for="item in identityList"
           :key="item.val">
        <!-- <img :src="`https://img.infox-med.com/webImg/infoPc/collectUserInfo/${identityType=== item.val?item.activateIconUrl:item.iconUrl}`"
             alt=""> -->
        <img v-show="identityType=== item.val"
             :src="`https://img.infox-med.com/webImg/infoPc/collectUserInfo/${item.activateIconUrl}`"
             alt="">
        <img v-show="identityType!== item.val"
             :src="`https://img.infox-med.com/webImg/infoPc/collectUserInfo/${item.iconUrl}`"
             alt="">
        <span>{{item.label}}</span>
      </div>
    </div>
    <div class="confirm flc" @click="confirmIdentity">确定</div>
  </div>
</template>

<script>
import { identityList } from '../map'
export default {
  props: {
    identityType: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      selectIdentity: '',
      identityList: identityList
    }
  },
  methods: {
    confirmIdentity () {
      if (!this.selectIdentity) return this.$myMessage.warning('请选择身份')
      this.$emit('confirmIdentity')
    },
    changeIdentity (item) {
      this.selectIdentity = item.val
      this.$emit('update:identityType', item.val)
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.identity_list {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
  margin-bottom: 30px;
  .identity_item {
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    row-gap: 10px;
    img {
      width: 72px;
      height: 72px;
    }
    span {
      font-size: 14px;
      color: #666;
      font-weight: 600;
    }
  }
}
.confirm {
  width: 188px;
  height: 37px;
  background: linear-gradient(90deg, #03d7ff 0%, #7f25fc 100%);
  border-radius: 28px;
  font-size: 18px;
  font-weight: 600;
  margin: auto;
  color: #ffffff;
}
</style>
