<template>
  <el-row>
    <el-col :span="24">
      <div class="publicBtm">
        <!-- 公共底部 -->
        <div class="public_btm">
          <div class="btm_title">
            <div class="btm_left">
              <p>权威数据<span>汇集多个国际权威、前沿数据库 专业医学内容，超千万量级</span> </p>
              <p>精准搜索<span>一键精准搜索 ，内容全面，涵盖指南、文献、临床、公开课</span> </p>
              <p>智能推送<span>实时更新推送最新国际医疗资讯内容 实时更新推送国际学科前沿课程</span></p>
              <p>友情连接<span><a href="http://www.ikangxun.com">病例分享</a></span></p>
            </div>
            <div class="btm_right">
              <!-- 关于我们入口 -->
              <div class=" hand animated" :class="{ 'swing' : donghua }">
                <i @click="goAboutUsPage" @mouseover="dong" class="iconfont icon-Aboutus"></i>
              </div>
              <div>
                <img src="@/assets/img/index/qsCode.png" alt="infoxmed">
                <p>关注info</p>
              </div>
              <div>
                <img src="https://img.infox-med.com/webImg/infoPc/downloadAppCode.png"
                     alt="infoxmed">
                <p>扫码下载APP</p>
              </div>
              <div>
                <img class="wechantCode_box"
                     src="https://img.infox-med.com/webImg/public/wechatCode.png" alt="infoxmed">
                <p>联系客服</p>
              </div>
            </div>
          </div>
          <div class="btmFiling">
            Copyright © 医赋知识（北京）科技有限公司 |
            <a href="https://beian.miit.gov.cn">京ICP备2022020960号</a>
            <span class="ml20">备案编号：（京）网药械信息备字（2024）第00078号</span>
            <span class="tongji" ref="tongji" v-html="jiance()"></span>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script
    type="text/javascript"></script>
<script>
export default {
  name: 'indexButtom',
  data () {
    return {
      donghua: false
    }
  },
  methods: {
    // 站长检测工具
    jiance () {
      const src = decodeURIComponent("%3Cspan id='cnzz_stat_icon_1280050831'%3E%3C/span%3E")
      return src
    },
    // 跳转到关于我们
    goAboutUsPage () {
      if (this.$route.path === '/aboutUs') return
      this.$router.push('/aboutUs')
    },
    // 动画
    dong () {
      this.donghua = true
      setTimeout(() => {
        this.donghua = false
      }, 1000)
    }
  },
  created () {
    // this.jiance()
  }
}
</script>
<style lang="less" scoped>
.publicBtm {
  width: 100%;
  background-color: #000;
  z-index: 99;
  .public_btm {
    color: #fff;
    padding: 20px 0px;
    font-size: 16px;
    width: 1200px;
    margin: 0 auto;
    background-color: #000;
    .btm_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btm_left {
        p {
          margin: 10px 0;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            margin: 0 0 0 30px;
          }
          a {
            color: #fff;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .btm_right {
        display: flex;
        align-items: center;
        div {
          margin: 0 20px;
          text-align: center;
        }
        img {
          width: 80px;
          height: 80px;
          margin: 0 0 8px 0;
        }
      }
    }
    .btmFiling {
      text-align: center;
      margin: 13px 0 0;
      a,
      a:hover {
        color: #fff;
      }
    }
  }
}
.ml20 {
  margin-left: 20px;
}
.wechantCode_box {
  padding: 3px;
  border-radius: 3px;
  background-color: #fff;
}
</style>
