import request from '@/utils/request.js'
// 提交文献相关反馈
export function submitDocFeedback (data) {
  return request({
    url: '/system/feedback/insertDocumentFeedback',
    data
  })
}

// 提交系统反馈
export function submitSystemFeedback (data) {
  return request({
    url: '/system/feedback/insertSysFeedback',
    data
  })
}

// 获取反馈消息
export function getFeedbackList (data) {
  return request({
    url: '/system/feedback/getMyFeedback',
    data
  })
}

// 修改反馈阅读状态
export function updateIsRead (data) {
  return request({
    url: '/system/feedback/updateIsRead',
    data
  })
}

// 获取消息推送列表
export function getMsgList (data) {
  return request({
    url: '/system/message/getReleasedMessage',
    data
  })
}
