<template>
  <div class="register">
    <MyTab text="快速注册" />
    <div class="register_content">
      <MyForm :form="form" @phoneBlur="phoneBlur" />
      <p class="warning_text">密码由8-12位字母、数字组成，不能是纯数字/字母组成</p>
      <div class="login_btn_box mb10">
        <el-button :disabled="!isRegister" class="login_btn" type="primary" @click="register">
          注 册
        </el-button>
      </div>
    </div>
    <p class="tc_6 bold fs14 hand tac mb10" @click="toLogin">快速登录></p>
    <Agreement ref="Agreement" :isLogin="isLogin" @checkChange="checkChange" />
  </div>
</template>

<script>
import MyTab from './tab.vue'
import MyForm from './loginForm.vue'
import Agreement from './agreement.vue'
import { register, isRegister } from '@/api/user'
import { mapMutations } from 'vuex'
export default {
  components: {
    MyForm,
    MyTab,
    Agreement
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        phone: '',
        phoneError: '',
        verifyCode: '',
        verifyCodeError: '',
        password: '',
        passwordError: '',
        confirmPassword: '',
        confirmPasswordError: ''
      },
      ischeckbox: false,
      toLoginName: 'QrCode'
    }
  },
  methods: {
    ...mapMutations('user', ['updateToken']),
    async phoneBlur () {
      try {
        this.form.phoneError = ' '
        const res = await isRegister({
          phone: this.form.phone
        })
        if (res.data) {
          this.toLoginName = 'ShortMsg'
          this.form.phoneError = '该手机号已注册'
        } else this.form.phoneError = ''
      } catch (error) {
        this.$myNotify.warning(error || '服务错误')
      }
    },
    checkChange (type) {
      this.ischeckbox = type
    },
    async register () {
      try {
        if (!this.isRegister) return
        if (!this.ischeckbox) return this.$refs.Agreement.animation()
        const res = await register({
          phone: this.form.phone,
          passWord: this.form.password,
          registerFrom: 'PC-手机号密码注册',
          verifyCode: this.form.verifyCode
        })
        this.$myCount({
          name: 'PC-注册成功',
          successCallback: () => {
            this.updateToken(res.data.obj)
            this.$closeLogin()
            window.location.reload()
          }
        })
      } catch (error) {
        this.$myNotify.warning(error || '注册失败')
      }
    },
    toLogin () {
      this.$emit('toLogin', this.toLoginName)
    }
  },
  computed: {
    isRegister () {
      return this.form.phone && this.form.verifyCode && this.form.password && this.form.confirmPassword && !this.form.phoneError && !this.form.verifyCodeError && !this.form.passwordError && !this.form.confirmPasswordError
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.register {
  padding-bottom: 20px;
}
.register_content {
  padding: 0 50px 0;
  .warning_text {
    color: #979797;
    margin-bottom: 10px;
  }
  .login_btn_box {
    width: 100%;
    height: 40px;
    .login_btn {
      width: 100%;
      height: 100%;
    }
  }
}
/deep/.input_box {
  margin-bottom: 18px;
}
/deep/.tab {
  margin-bottom: 10px;
}
</style>
