export default {
  namespaced: true,
  state: () => ({
    isNavBg: false
  }),
  mutations: {
    // 导航栏背景
    setNavBg (state, type) {
      state.isNavBg = type
    }
  }
}
