<template>
  <div class="hospital_container fs14">
    <el-select :value="value" @input="input" placeholder="请选择职称"
               popper-class="userinfo_dialog_select" default-first-option>
      <el-option v-for="item in list" :key="item" :label="item" :value="item">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    identityType: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      titleMap: {
        1: ['未定级医生', '住院医师', '主治医师', '副主任医师', '主任医师', '医师'],
        2: ['未定级药师', '药士', '药师', '主管药师', '副主任药师', '主任药师'],
        3: ['护士', '护师', '主管护师', '副主任护师', '主任护师'],
        4: ['技师', '主管技师', '副主任技师', '主任技师'],
        5: ['未定级', '实习研究员', '助理研究员', '副研究员', '研究员'],
        6: ['未定级', '助教', '讲师', '副教授', '教授']
      }
    }
  },
  methods: {

    input (val) {
      this.$emit('input', val)
    }

  },
  computed: {
    list () {
      return this.titleMap[this.identityType] || []
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.hospital_container {
  position: relative;
  height: 100%;
}
.el-select {
  width: 100%;

  /deep/.el-input__inner {
    background: #f5f7fb;
    border-radius: 28px;
  }
}
</style>
