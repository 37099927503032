<template>
  <div class="loading">
    <!-- 单个文献的封装  -->
    <!-- 1.标题  IF  DOI  FMID  -->
    <template v-if="honDocuList.length>0">
      <div class="list" v-for="( item,index ) in honDocuList" :key="index"
           :class="{'select_item':isRadio}">
        <!-- <el-radio v-if="isRadio" v-model="item.radio" :label="item.id"></el-radio> -->
        <div v-if="isRadio">
          <el-checkbox v-model="item.radio" :class="{'isSelectCheck':item.radio }"
                       @change="onCheckboxChange(item)"></el-checkbox>
        </div>
        <div class="docinfo">
          <!-- 文章标题 ,'title_isAct':isRad(item.id)-->
          <div :class="{'title_isAct':read(item.id),'temporary_isAct':isRad(item.id)}"
               class="title color">
            <!-- 序号 -->
            <span v-if="isIndex">
              {{i+index+1}}.
            </span>
            <img v-if="item.qiniuUrl === '1' && !onIsRevoke(item.docPublishType) && !isIcon"
                 class="pdf_icon" src="@/assets/img/list_pdf_icon.png">
            <img v-if="onIsRevoke(item.docPublishType) && item.qiniuUrl !== '1' && !isIcon"
                 class="pdf_icon" src="@/assets/img/docuInfo/revoke_icon.png">
            <img v-if="onIsRevoke(item.docPublishType) && item.qiniuUrl === '1' && !isIcon"
                 class="pdf_icon" src="../../assets/img/docuInfo/revokePdf_icon.png">
            <!--下载设备指示图标  -->
            <span class="equipment-icon" v-if="isIcon">
              <!-- app  web 顺序 -->
              <i class="iconfont icon-a-shoujiiconqiepian3x" v-if="item.channel===0"></i>
              <i class="iconfont icon-a-diannaoiconqiepian" v-if="item.channel===1"></i>
            </span>
            <!-- 文章标题 -->
            <a v-if="$store.getters.userId" class="document_title"
               :href="`https://www.infox-med.com/#/articleDetails?id=${item.id}`"
               @click="titleClick($event,item)"
               v-html="highlight(item.highLightDocTitle || item.docTitle , 1)">
            </a>
            <span v-else @click="titleClick($event,item)" class="document_title"
                  v-html="highlight(item.highLightDocTitle || item.docTitle, 1)">
            </span>
            <i v-if="!item.docTitleZh" class="iconfont icon-wenxianfanyi title_translate_icon hand"
               @click.stop="translate(item)"></i>
          </div>

          <div class="zh_title hand" v-if="item.docTitleZh">
            <a v-if="$store.getters.userId"
               :href="`https://www.infox-med.com/#/articleDetails?id=${item.id}`"
               @click="titleClick($event,item)">
              {{item.docTitleZh}}
            </a>
            <span v-else @click="titleClick($event,item)">
              {{item.docTitleZh}}
            </span>
          </div>
          <!-- 文章说明  -->
          <div class="marking document_text">
            <div class="tags_container">
              <div class="area_tag" v-if="item.zkyArea">中科院分区：{{item.zkyArea}}</div>
              <div class="cited_tag" v-if="item.citedBy">被引用: {{item.citedBy}}</div>
            </div>
            <div class="xuanxiang">
              <div class="highlight_info">IF: {{item.docIf || '无'}}</div>
              <div v-if="false" class="highlight_info">PMID: {{item.pmid || '无'}}</div>
              <div class="highlight_info">DOI: {{item.docDoi || '无'}}</div>
              <div class="time highlight_info">日期:
                {{$initTime(item.docPublishTime) || '无'}}</div>
            </div>

            <div class="qikan-box">
              <div @click="goJournalDetailsPage(item)" class="highlight_info">
                期刊: <span :class="{'is_hover':item.journalId,'hand':item.journalId}">
                  {{item.docSourceJournal || '无'}}</span> </div>

            </div>
            <div v-if="item.docAuthor" class="docAuthor row_1">作者:
              <span v-html="highlight(item.highLightDocAuthor || item.docAuthor, 2)"></span>
            </div>
            <div v-if="item.docPublishType" class="docType row_1">类型:
              {{item.docPublishType.trim() || '暂无'}}</div>
            <div v-if="item.docKeywords" class="docType row_1">关键词:
              <span v-html="highlight(item.highLightDocKeywords || item.docKeywords, 2)"></span>
            </div>
            <div class="abstract_container" v-if="item.docAbstract">
              <div class="abstract_item">
                <div class="abstract " :class="{'row_1':!doclist[index].isUnfoldAbs}"
                     v-html="highlight(item.highLightDocAbstract || item.docAbstract, 2)"></div>
                <img @click="unfild('isUnfoldAbs', index)"
                     src="https://img.infox-med.com/webImg/infoPc/icon/unfild_icon.png"
                     class="unfild_icon" :class="{'rotate_unfild': doclist[index].isUnfoldAbs}"
                     alt="">
              </div>
              <div class="abstract_item" v-if="item.docAbstractZh">
                <div class="abstract" :class="{'row_1':!doclist[index].isUnfoldAbsZh}">
                  {{item.docAbstractZh}}</div>
                <img class="unfild_icon" :class="{'rotate_unfild': doclist[index].isUnfoldAbsZh}"
                     src="https://img.infox-med.com/webImg/infoPc/icon/unfild_icon.png" alt=""
                     @click="unfild('isUnfoldAbsZh', index)">
              </div>
            </div>
            <div class="use_containre">
              <div class="exportFile hand">
                <el-dropdown @command="exportFileCommand($event,item)" :show-timeout="0"
                             trigger="click">
                  <div class="el-dropdown-link">
                    导出
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="exportType in exportFileTypes" :key="exportType.id"
                                      :command="exportType.id">{{exportType.label}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
    <placeholder v-else :isShow="honDocuList.length===0 && isShowEmpty" marginTop="20px" />
  </div>
</template>
<script>
import { getMyRead, setMyRead } from '@/utils/storage.js'
import { blankPagePar } from '@/utils/index.js'
import { translateByDocId } from '@/api/article.js'
export default {
  name: 'list',
  props: {
    // 列表数据
    honDocuList: {
      type: Array,
      default: () => []
    },
    moInput: {
      type: String,
      default: ''
    },
    // 列表总数
    i: {
      default: 0
    },
    // 序号状态位
    isIndex: {
      type: Boolean,
      default: true
    },
    // 下载设备指示图标
    isIcon: {
      type: Boolean,
      default: false
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    isShowEmpty: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      idList: [],
      doclist: [],
      docListMap: [],
      exportFileTypes: [
        {
          label: 'Endnote',
          id: 'enw'
        }, {
          label: 'Noteexpress',
          id: 'net'
        }
      ]
    }
  },
  methods: {
    exportFileCommand (type, item) {
      window.open(`https://api.infox-med.com/home/getDocumentMetaInfoFile?documentId=${item.id}&filetype=${type}`)
    },
    unfild (key, index) {
      const find = this.doclist[index]
      find[key] = !find[key]
    },
    updateItem (newDate, key) {
      const findIndex = this.doclist.findIndex(item => item.id === newDate.id)
      if (findIndex !== -1) {
        key ? this.doclist[findIndex][key] = newDate[key] : this.$set(this.doclist, findIndex, newDate)
      }
    },
    highlight (text, type) {
      const stylemap = {
        1: 'font-weight: 600; font-size: 18px;color: #c05600;',
        2: 'font-weight: 600; font-size: 14px;color: #000; '
      }
      return text ? text.replace(/<span/g, `<span style="${stylemap[type]}"`) : text
    },
    // 翻译
    async translate (item) {
      try {
        if (!this.$store.getters.userId) return this.$login()
        if (item.docTitleZh) return
        this.$myCount('PC-列表翻译')
        const res = await translateByDocId({
          documentId: item.id
        })
        item.docTitleZh = res.data.docTitleZh
        item.docAbstractZh = res.data.docAbstractZh
      } catch (error) {
      }
    },
    // 阻止a标签默认行为
    titleClick (event, item) {
      const e = event || window.event
      // 阻止默认行为
      // return false
      e.preventDefault()
      e.returnValue = false
      // 兼容阻止默认行为
      e.preventDefault ? e.preventDefault() : e.returnValue = false
      this.skip(item)
      // return false
    },
    // 判断是否是撤稿
    onIsRevoke (val) {
      if (!val) return false
      return ['Retracted Publication', '已撤销出版物', 'Retraction of Publication'].includes(val)
    },
    // 复选框改变事件
    onCheckboxChange (item) {
      this.$emit('checkboxChange', item)
    },
    // 根据期刊名字获取期刊详情
    async goJournalDetailsPage (item) {
      try {
        if (!item.journalId) return
        if (this.$route.path === '/journalDetails') return this.$message.warning('已在当前期刊')
        blankPagePar('journalDetails', { journalId: item.journalId })
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 判断是否阅读过此文章
    read (id) {
      // return false
      if (this.$route.path === '/userInfo/read') return
      const arrId = JSON.parse(getMyRead() || '[]').map(item => item.id)
      return arrId.includes(id)
    },
    isRad (id) {
      if (this.$route.path === '/userInfo/read') return
      return this.idList.includes(id)
    },
    //  标题匹配多个字段高亮
    keysLight (content, keyWord) {
      if (!keyWord) return content
      const searchArray = keyWord.split(' ').filter(item => item.length && !/^\W$/.test(item)).map(item => item.replace(/\W/g, ''))
      // const searchArray = keyWord.split(' ').filter(item => item.length)
      const keys = [...new Set(searchArray)].sort((a, b) => { return b.length - a.length })
      let text = content
      keys.forEach(key => {
        let num = -1 // 存放html数组对应的索引
        const regKey = new RegExp(`${key}`, 'gi')
        const val = text.match(regKey) ? text.match(regKey)[0] : key
        if (!val) return
        const regHtml = new RegExp('<.*?>', 'ig') // 匹配html元素
        const arrayHtml = text.match(regHtml) // 存放html元素的数组
        text = text.replace(regHtml, '{~}') // 替换html标签
        const stringKey = `<span style="font-weight: 600; font-size: 18px;color: #c05600; ">${val}</span>`
        text = text.replace(regKey, stringKey) // 替换关键字
        text = text.replace(/{~}/g, function () {
          // 恢复html标签
          num++
          return arrayHtml[num]
        })
      })
      return text
    },
    // 摘要高亮
    abstractPiPei (content, keyWord) {
      if (!keyWord) return content
      // 搜索文字处理:  以空格分隔关键字, 去重，倒序(先匹配文字多的，再匹配文字少的)
      const searchArray = keyWord.split(' ').filter(item => item.length && !/^\W$/.test(item)).map(item => item.replace(/\W/g, ''))
      const keys = [...new Set(searchArray)].sort((a, b) => { return b.length - a.length })
      let text = content
      keys.forEach(key => {
        let num = -1 // 存放html数组对应的索引
        const regKey = new RegExp(`${key}`, 'gi')
        const val = text.match(regKey) ? text.match(regKey)[0] : key
        if (!val) return
        const regHtml = new RegExp('<.*?>', 'ig') // 匹配html元素
        const arrayHtml = text.match(regHtml) // 存放html元素的数组
        text = text.replace(regHtml, '{~}') // 替换html标签
        const stringKey = `<span style="font-weight: 600; font-size: 14px;color: #000; ">${val}</span>`
        text = text.replace(regKey, stringKey) // 替换关键字
        text = text.replace(/{~}/g, function () {
          // 恢复html标签
          num++
          return arrayHtml[num]
        })
      })
      return text
    },
    // 跳转到文章详情 并保存本地
    skip (item) {
      if (!this.$store.getters.userId) return this.$login()
      let arr = JSON.parse(getMyRead() || '[]')
      arr.unshift({
        id: item.id
      })
      arr = arr.map(items => {
        return JSON.stringify(items)
      })
      arr = [...new Set(arr)]
      arr = arr.map(it => {
        return JSON.parse(it)
      })
      setMyRead(arr)
      if (!this.idList.includes(item.id)) {
        this.idList.unshift(item.id)
      }
      if (this.idList.length > 10) {
        this.idList.pop()
      }
      this.$myCount({
        name: 'PC-文献/指南访问',
        itemId: item.id
      })
      blankPagePar('articleDetails', { id: item.id })
    },
    setDoclist (list) {
      return list.map(item => ({
        id: item.id,
        isUnfoldAbs: false,
        isUnfoldAbsZh: false
      }))
    }
  },
  watch: {
    honDocuList: {
      handler (newList) {
        const l = newList.length - this.doclist.length
        if (l > 0) {
          const changeList = newList.slice(this.doclist.length)
          const newDocList = this.setDoclist(changeList)
          this.doclist = [...this.doclist, ...newDocList]
        } else {
          this.doclist = []
          this.doclist = this.setDoclist(newList)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.loading {
  // color: #4b639f;
  p {
    margin-top: 20px;
  }
  .list {
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
    align-items: start;
    background-color: #fff;
    border-radius: 2px;
    padding: 15px;
    margin: 15px 0;
    // color: #4b639f;
    transition: all 0.5s;
    // 后期可能会改
    &:hover {
      // box-shadow: 0 0 0 0 #fff;
      box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
      // box-shadow: inset 4px 4px 7px 0 rgba(204, 204, 204, 0.2);
      transition: all 0.3s;
    }
    // 复选框
    /deep/ .isSelectCheck {
      .el-checkbox__inner {
        animation: isSelectCheckAnimation 0.3s ease;
      }
    }
    @keyframes isSelectCheckAnimation {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(10deg);
      }
      50% {
        transform: rotate(0deg);
      }
      75% {
        transform: rotate(-10deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    .docinfo {
      width: 100%;
    }
    .highlight_info {
      color: #4d8055;
    }

    .title {
      font-size: 18px;
      color: #4b639f;
      margin-bottom: 5px;
      .equipment-icon {
        i {
          margin-right: 10px;
          font-size: 26px;
          color: #4b639f;
        }
      }
      .pdf_icon {
        position: relative;
        top: -3px;
        width: 16px;
        margin: 0 5px;
      }
      .title_translate_icon {
        font-size: 20px;
        margin-right: 0;
        margin-left: 10px;
      }
    }
    .document_title {
      color: #4b639f !important;
    }
    .title_isAct,
    .temporary_isAct {
      color: #8132b5 !important;
      .document_title {
        color: #8132b5 !important;
      }
    }
    .zh_title {
      margin-bottom: 5px;
      &:hover {
        text-decoration: underline #444;
        text-underline-offset: 4px;
      }
      a,
      span {
        font-size: 14px;
        color: #333;
      }
    }

    .marking {
      margin: 8px 0 0;
      font-size: 14px;
      color: #666;
      align-items: center;

      .docAuthor {
        word-break: break-all;
        color: #666;
        margin: 3px 0;
        line-height: 20px;
        font-size: 14px;
      }
      .docType {
        width: 846px;
        word-break: break-all;
        color: #666;
        margin: 3px 0;
        line-height: 20px;
        font-size: 14px;
      }
      .abstract {
        // 强制三行显示超出...代替
        word-break: break-all;
        color: #666;
        margin: 3px 0;
        line-height: 1.3em;
        font-size: 14px;
        // text-decoration: underline;
      }
      .abstract_zh {
        background-color: #f5f7fb;
        padding: 10px;
        border-radius: 4px;
        line-height: 1.5em;
      }
      .xuanxiang {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        // padding: 0 82px 0 0;
        div {
          max-width: 40%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .time {
          position: relative;
          top: -1px;
        }
      }
    }
  }
  .select_item {
    grid-template-columns: 24px calc(100% - 24px);
  }

  .document_title:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
  .el-empty {
    width: 500px;
    height: 500px;
    margin: 0 auto;
  }
}
.qikan-box {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  // padding-right: 82px;
}

/deep/.el-checkbox__inner {
  margin: 3px 10px 0 0;
  background-color: #f1f3f9;
}

.abstract_container {
  border-radius: 2px;
  padding: 0 10px;
  background-color: #f5f7fb;
  .abstract_item {
    display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 5px;
    padding: 8px 0;
    font-size: 14px;
    color: #666;
    border-bottom: 1px solid #e6e6e6;
    &:last-child {
      border-bottom: 0;
    }
  }
  .unfild_icon {
    width: 100%;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.3s;
  }
  .rotate_unfild {
    transform: rotate(180deg);
  }
}
.tags_container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  margin-bottom: 5px;
  .area_tag,
  .cited_tag {
    font-size: 12px;
    padding: 2px 8px;
    border: 1px solid;
    border-radius: 3px;
  }
  .area_tag {
    border-color: #507770;
    background-color: #eaf9f6;
    color: #507770;
  }
  .cited_tag {
    border-color: #b66b3a;
    color: #b66b3a;
    background-color: #fae6d9;
  }
}

.use_containre {
  display: grid;
  align-items: center;
  justify-content: end;
  padding-top: 10px;

  .exportFile {
    .el-dropdown-link {
      padding: 2px 10px;
      border-radius: 4px;
      color: #4b639f;
      border: 1px solid #4b639f;
      &:hover {
        background-color: #4b639f;
        color: #fff;
        .el-icon--right {
          color: #fff;
        }
      }
    }
    .el-icon--right {
      margin-left: 0;
      color: #888;
    }
    // // width: 60px;
    // padding: 3px 15px;
    // border-radius: 4px;
    // // background-color: #4b639f;
    // border: 1px solid #4b639f;
    // &:hover {
    //   background-color: #4b639f;
    //   .el-dropdown-link {
    //     color: #fff;
    //   }
    // }
  }
}
</style>
