<template>
  <div class="tab mb20">
    <span class="bold fs18">{{text}}</span>
    <i class="tab_line"></i>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.tab {
  position: relative;
  padding: 0 0 8px 40px;
  .tab_line {
    position: absolute;
    left: 60px;
    bottom: 0;
    width: 30px;
    height: 3px;
    background-color: #4b639f;
  }
}
</style>
