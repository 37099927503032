<template>
  <!-- 首页 -->
  <div class="box" @click="hideBox">
    <div class="index" :class="{'is_bgColor':isNavBg}">
      <!-- <video class="video_bg_box" src="@/assets/img/index/index.bg.mp4" autoplay controls></video> -->
      <div class="video_box" :class="{'is_video':isNavBg}">
        <video id="video_bg_box" src="@/assets/img/index/index_bg2.mp4" autoplay="autoplay"
               loop="loop" muted="muted" width='100%' webkit-playsinline="true"
               playsinline="true"></video>
      </div>
      <div class="zhong-top" :class="{'is_textColor':isNavBg}">
        <!-- 2.0--主页的搜索框 -->
        <div class="search_logo no_select">
          <span class="index_title">Info X Med</span>
          <!-- <span class="index_title">INFO X MED</span> -->
        </div>
        <div class="search_title no_select">深 耕 医 生 领 域 医 学 知 识 平 台</div>
        <div class="search" @click.stop>
          <el-input placeholder="输入中英文关键词/英文标题/DOI/PMID" v-model="input" clearable
                    @keyup.enter.native="gainSearch" @focus="onOpenSearchProposal"
                    @input="inputValChange">
            <i v-if="false" slot="prefix" class="searchIcon">
              <img src="@/assets/img/index/searchIcon.png">
            </i>
          </el-input>
          <!-- :class="{'is_searchBtn_color':isNavBg, 'is_textColor':isNavBg}" -->
          <el-button class="input_elbtn" style="" @click="gainSearch" slot="append">Search
          </el-button>
          <p class="Advanced  hand" @click="goHeightSearch">Advanced</p>
          <div v-show="isSearchProposal" class="search_proposal_box">
            <searchProposal :inputVal="input" @valChange="valChange" />
          </div>
        </div>
        <div class="login_btm" v-if="false">
          <div class="btm_list" v-for="(item,index) in login_btmList" :key="index">
            <img :src="item.img" alt="">
            <p>{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间下面内容 -->
    <div class="zhong-bom">
      <div class="bom-item">

        <div class="bom-left">
          <div class="left-title">
            <div class="publicTitle">
              热门文献
              <p></p>
            </div>
          </div>

          <div class="left-item">
            <!-- 文章列表组件 -->
            <article-list :honDocuList="honDocuList" />
            <div class="more">
              <span class=" hand" @click="lookMore">查看更多>></span>
            </div>
          </div>
        </div>

        <div class="bom-right" v-if="false">
          <div class="right-title">
            <div class="publicTitle">
              我的查阅
              <p></p>
            </div>
          </div>
          <!-- 登录按钮 -->
          <div class="rightCont" v-if="!token">
            <img class=" hand" src="@/assets/img/tou.png" @click="register">
            <el-tooltip content="点击头像即可登录" placement="bottom" effect="light">
              <div class="loginHint">
                <p>您还未登录</p>
                <p>登录后即可体验更多功能</p>
              </div>
            </el-tooltip>
            <!-- <div @click="register" class="loginBtm">Log in</div> -->
          </div>
          <div class="bll" v-else>
            <!-- 个人收藏展示 -->
            <my-refer />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { getArticleList } from '@/api/article.js'
import { gainUserInfo } from '@/api/user.js'
import { setHistory, getHistory } from '@/utils/storage.js'
import myRefer from './componens/myRefer.vue'

import searchProposal from '@/components/searchProposal/index.vue'
import Player from 'xgplayer'
export default {
  name: 'Index',
  components: {
    myRefer,
    searchProposal
  },
  data () {
    return {
      input: '', // 搜索框值
      login_btmList: [
        { id: 0, img: require('@/assets/img/index/icon1.png'), name: '权威数据' },
        { id: 1, img: require('@/assets/img/index/icon2.png'), name: '精准搜索' },
        { id: 2, img: require('@/assets/img/index/icon3.png'), name: '文献翻译' },
        { id: 3, img: require('@/assets/img/index/icon4.png'), name: '多端同步' }
      ],
      honDocuList: [], // 保存热门文献
      obj: {
        pageNum: 1,
        pageSize: 5
      },
      historyLishi: [], // 搜索历史
      isSearchProposal: false, // 搜索建议
      player: {},
      timeId: null
    }
  },
  methods: {
    ...mapMutations('user', ['removeToken']),
    ...mapMutations('login', ['setNavBg']),
    // 首页背景
    initVideo () {
      this.player = new Player({
        id: 'index_bg_Box', // 每个播放器的dom标识
        url: 'http://img.infox-med.com/a103a5f0746a5ec72866471008276732.mp4', // 视频路径
        // poster: imgUrl, // 封面图
        width: '100%', // 播放器宽度
        // height: '100%',
        // fitVideoSize: 'fixWidth',
        controls: false,
        fluid: true,
        volume: 0, // 初始音量
        loop: true,
        keyShortcut: 'off',
        autoplay: true // 自动播放
        // playbackRate: [0.5, 0.75, 1, 1.5, 2], // 倍速
        // defaultPlaybackRate: 2// 默认倍速1
      })
    },
    // 搜索建议框值改变
    valChange (keyword) {
      this.input = keyword
      this.gainSearch()
    },
    // 关闭搜索建议
    hideBox () {
      if (this.isSearchProposal) {
        this.isSearchProposal = false
      }
    },
    // 搜索框获取焦点显示
    onOpenSearchProposal () {
      if (!this.input) {
        this.isSearchProposal = true
      }
    },
    // 搜索框值变化
    inputValChange () {
      // console.log(this.input)
      if (this.input) {
        this.isSearchProposal = false
      } else {
        this.isSearchProposal = true
      }
    },
    // 跳转到高级搜索
    goHeightSearch () {
      // this.$router.push({ path: '/searchList', query: { val: 'index' } })
      this.$router.push('/advanceSearch')
    },
    // 查看登录是否过期
    async gobalBuyer () {
      const res = await gainUserInfo({ userId: this.userId })
      if (res.code === 401) {
        this.removeToken()
      }
    },
    // 查看更多
    lookMore () {
      // 加载更多热门文献
      this.obj.pageNum++
      this.getArticleList()
    },
    // 搜索
    gainSearch () {
      const reg = /^\s+$/g
      const isInput = reg.test(this.input)
      if (this.input === '' || isInput) return this.$message.warning('搜索内容不可为空!')
      let arr = JSON.parse(getHistory() || '[]')
      arr.unshift(this.input)
      arr = [...new Set(arr)]
      setHistory(arr)
      this.$myCount('PC-搜索文献')
      // 跳转到搜索页面
      this.$router.push({ path: '/searchList', query: { val: this.input } })
      // blankPagePar('searchList', { val: this.input })
    },
    // 登录
    register () {
      this.$refs.register.register()
    },
    // 获取热门文献
    async getArticleList () {
      try {
        // this.isLog = true
        const res = await getArticleList(this.obj)
        // this.isLog = false
        if (res.msg !== 'success') return this.$message.error('获取热门文献失败')
        // 点击查看更多追加热门文献
        const data = res.data
        this.honDocuList = [...this.honDocuList, ...data]
      } catch (error) {
        return this.$message.error('获取热门文献失败')
      }
    }
  },

  computed: {
    ...mapGetters(['token', 'userId', 'isNavBg'])
  },
  mounted () {
    this.timeId = setTimeout(() => {
      this.setNavBg(true)
    }, 1000 * 11)
    const video = document.querySelector('#video_bg_box')
    document.addEventListener('WeixinJSBridgeReady', function () {
      video.play()
    }, false)
  },
  beforeDestroy () {
    clearTimeout(this.timeId)
  },
  created () {
    this.getArticleList()
    // 判断用户是否登录过期
    if (this.token) {
      this.gobalBuyer()
    }
  }
}
</script>
<style lang="less" scoped>
.index_title {
  font-size: 38px;
  font-weight: 700;
  font-family: 'Baskerville';
}
.box {
  width: 100%;
  color: #4b639f;
}
.index {
  position: relative;
  // top: -52px;
  // margin-top: -52px;
  width: 100%;
  // background: url('../../assets/img/index/indexBg.png') no-repeat;
  // background-size: 100% 100%;
  // overflow: hidden;
  // background-color: transparent;
  background-color: #f5f7fb;
  transition: backgroundColor 0.5s;
  .video_box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.8s;
    overflow: hidden;
  }
  .is_video {
    opacity: 0;
  }
  #index_bg_Box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    height: 100% !important;
    // overflow: hidden;
    /deep/ .xgplayer-start {
      display: none;
    }
  }
  .zhong-top {
    position: relative;
    top: -30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    // height: 859px;
    min-height: 700px;
    // height: calc(100vh - 52px);
    height: 100vh;
    // color: var(--indexcolor);
    color: #fff;
    transition: color 0.5s;
    // padding: 200px 200px 0;
    text-align: center;
    .search_logo {
      width: 270px;
      height: 30px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .search_title {
      margin: 25px 0 30px;
      font-size: 18px;
      // text-shadow: 1px 1px 0px #4b639f;
    }
    .search {
      display: flex;
      position: relative;
      width: 600px;
      margin: 0 auto;
      border-radius: 50px;
      box-shadow: 0px 0px 0px 0px #000;
      // 后期估计要改
      .el-input {
        &:hover {
          // box-shadow: 0px 0px 10px 0px #ccc;
        }
      }
      .Advanced {
        position: absolute;
        bottom: -35px;
        left: 0px;
        right: 0;
        margin: 0 auto;
        font-size: 18px;
        padding-right: 93px;
        &:hover {
          text-decoration: underline;
        }
      }
      .input_elbtn {
        // color: var(--indexcolor);
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        flex-shrink: 0;
        border-radius: 0px 4px 4px 0px;
        background-color: #4b639f;
        transition: all 0.5s;
        border-color: #4b639f;
        &:focus,
        &:hover {
          border-color: #4b639f;
        }
      }
      .is_searchBtn_color {
        background-color: #fff !important;
        border-color: #dcdfe6;
        &:focus,
        &:hover {
          border-color: #dcdfe6;
        }
      }
      .searchIcon {
        img {
          width: 24px;
          height: 24px;
        }
        margin-left: 25px;
      }
      /deep/ .el-input__prefix {
        top: 13px;
      }
      /deep/ .el-input-group {
        display: flex;
      }
      /deep/ .el-input__inner {
        width: calc(100% - 41px);
        height: 46px;
        box-sizing: content-box;
        padding: 0 20px 0 20px;
        line-height: 45px;
        font-size: 18px;
        border-radius: 4px 0 0 4px;
        &:hover,
        &:focus {
          border-color: #dcdfe6;
        }
      }
      /deep/.el-input__inner {
        // width: calc(100% - 90px);
        &::placeholder {
          font-size: 14px;
          line-height: 45px;
        }
      }
      // 搜索建议
      .search_proposal_box {
        position: absolute;
        left: 0;
        top: 55px;
        width: 100%;
        min-height: 200px;
        z-index: 99;
      }
    }

    .login_btm {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      // padding: 0 140px;
      font-size: 20px;
      margin: 0 auto 30px;
      .btm_list {
        display: flex;
        align-items: center;
        // margin: 0 15px;
        img {
          width: 46px;
          height: 45px;
        }
        p {
          margin: 0 15px 0;
        }
      }
    }
  }
}
.is_textColor {
  color: #4b639f !important;
}
.is_bgColor {
  background-color: #fff !important;
}
.zhong-bom {
  width: 100%;

  background-color: #f5f7fb;

  .bom-item {
    width: 1200px;
    margin: 0 auto;
    min-height: 700px;
    // height: 911px;
    overflow: hidden;
    // display: flex;
    background-color: #f5f7fb;
    .bom-left {
      // width: 920px;
      width: 100%;
      height: 100%;
      .left-item {
        width: 100%;
        height: 100%;
        padding: 0 20px 30px 0;
        .more {
          text-align: center;
          margin: 30px 0 0;
          color: #4b639f;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    // 标题公共样式
    .publicTitle {
      width: 98px;
      font-size: 22px;
      font-weight: 600;
      color: #4b639f;
      margin: 30px 0 25px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        width: 45px;
        height: 4px;
        margin: 12px 0 0;
        background-color: #4b639f;
        border-radius: 5px;
      }
    }

    .bom-right {
      display: none;
      width: 320px;
      height: 100%;
      padding-left: 20px;
      .rightCont {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        font-size: 14px;
        // font-weight: 600;
        padding-left: 5px;
        align-items: center;
        img {
          border-radius: 50%;
          width: 60px;
          height: 60px;
        }
        .loginHint {
          width: 60%;
          margin: 0 35px 0 10px;
        }
        .loginBtm {
          padding: 5px 18px;
          border-radius: 2px;
          text-align: center;
          color: #fff;
          background-color: #4b639f;
          cursor: pointer;
          margin-left: 73px;
          &:hover {
            margin-top: -3px;
            box-shadow: 0 3px 6px 0 #4b639f;
            transition: margin-top 0.3s;
          }
        }
      }
    }
  }
}
// /deep/.el-input__inner::placeholder {
//   font-family: 'FangSong';
// }
</style>
