<template>
  <div class="form_content">
    <div class="input_box phone_input" v-if="form.phone !== undefined">
      <span class="region">+86</span>
      <i class="line"></i>
      <el-input @focus="phoneFocus" @blur="phoneBlur" size="mini" v-model="form.phone"
                placeholder="请输入手机号"></el-input>
      <span class="err_text">{{form.phoneError}}</span>
    </div>
    <div class="input_box code_input" v-if="form.verifyCode !== undefined">
      <el-input @focus="codeFocus" @blur="codeBlur" size="mini" v-model="form.verifyCode"
                placeholder="请输入验证码">
      </el-input>
      <span class="err_text">{{form.verifyCodeError}}</span>
      <VerifiCode ref="verifiCode" :beforeVerify="beforeVerify" :phone="form.phone" />
    </div>

    <div class="input_box" v-if="form.password !== undefined">
      <el-input @focus="passwordFocus" @blur="passwordBlur" size="mini" v-model="form.password"
                placeholder="请输入密码" show-password>
      </el-input>
      <span class="err_text">{{form.passwordError}}</span>
    </div>

    <div class="input_box confirmPassword_input" v-if="form.confirmPassword !== undefined">
      <el-input @focus="confirmPasswordFocus" @blur="confirmPasswordBlur" size="mini"
                v-model="form.confirmPassword" placeholder="请确认密码" show-password>
      </el-input>
      <span class="err_text">{{form.confirmPasswordError}}</span>
    </div>
  </div>
</template>

<script>
import VerifiCode from './verifiCode.vue'
export default {
  components: {
    VerifiCode
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {

    }
  },
  methods: {
    confirmPasswordBlur () {
      if (this.form.password && !this.form.confirmPassword) return (this.form.confirmPasswordError = '请输入确认密码')
      if (this.form.confirmPassword !== this.form.password) return (this.form.confirmPasswordError = '两次密码输入不一致')
    },
    confirmPasswordFocus () {
      this.form.confirmPasswordError = ''
    },
    passwordBlur () {
      if (!this.form.password) return
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/
      if (!reg.test(this.form.password)) {
        this.form.passwordError = '密码格式不正确'
      }
    },
    passwordFocus () {
      this.form.passwordError = ''
    },
    beforeVerify () {
      if (!this.form.phone) {
        this.form.phoneError = '请填写正确的手机号'
        return false
      }
      return !this.form.phoneError
    },
    codeBlur () {
      if (!this.form.verifyCode) return
      const reg = /^\d{6}$/
      if (!reg.test(this.form.verifyCode)) {
        this.form.verifyCodeError = '请填写正确的验证码'
      }
    },
    codeFocus () {
      this.form.verifyCodeError = ''
    },
    phoneFocus () {
      this.form.phoneError = ''
    },
    phoneBlur () {
      if (!this.form.phone) return
      const reg = /^1[3-9]\d{9}$/
      if (!reg.test(this.form.phone)) {
        this.form.phoneError = '请填写正确的手机号'
        return
      }
      this.$emit('phoneBlur')
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.form_content {
  // padding: 5px 50px 0;
  .input_box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    background-color: #f5f7fb;
    border-radius: 4px;
    /deep/ .el-input__inner {
      border: 0 !important;
      background-color: transparent;
    }
  }
  .region {
    color: #979797;
    padding: 0 10px 0 14px;
  }
  .phone_input {
    padding-right: 10px;
  }
  .line {
    width: 1px;
    height: 16px;
    background-color: #979797;
    margin-right: 10px;
  }
  .err_text {
    position: absolute;
    left: 0;
    bottom: -17px;
    color: #f00;
  }
}
</style>
