import { isDataType } from '@/utils/index'

// 本地储存方法
// 管理token
export function getToken () {
  return localStorage.getItem('userToken')
}
export function setToken (token) {
  return localStorage.setItem('userToken', token)
}
export function removeToken () {
  return localStorage.removeItem('userToken')
}

// 管理个人信息
const user = 'userInfo'
export function getUserInfo () {
  return localStorage.getItem(user)
}
export function setUserInfo (userInfo) {
  return localStorage.setItem(user, JSON.stringify(userInfo))
}
export function removeUserInfo () {
  return localStorage.removeItem(user)
}

// 管理历史搜索
const lishiji = 'history'
export function getHistory () {
  return localStorage.getItem(lishiji)
}
export function setHistory (lishi) {
  if (lishi.length >= 11) {
    lishi.pop()
  }
  localStorage.setItem(lishiji, JSON.stringify(lishi))
}
export function removeHistory () {
  localStorage.removeItem(lishiji)
}

// 管理最近阅读
const read = 'myRead'
export function getMyRead () {
  return localStorage.getItem(read)
}
export function setMyRead (arr) {
  if (arr.length >= 11) {
    arr.pop()
  }
  localStorage.setItem(read, JSON.stringify(arr))
}
export function removeMyRead () {
  localStorage.removeItem(read)
}

// 管理搜索删选条件
const filterSearch = 'filterSearchInfo'
export function getFilterSearchVal () {
  return sessionStorage.getItem(filterSearch) ? JSON.parse(sessionStorage.getItem(filterSearch)) : false
}
export function setFilterSearchVal (val) {
  sessionStorage.setItem(filterSearch, JSON.stringify(val))
}
export function removeFilterSearchVal () {
  sessionStorage.removeItem(filterSearch)
}

// 高级搜索条件
const advancrSearch = 'advancrSearch'
export function getAdvancrSearch () {
  return JSON.parse(sessionStorage.getItem(advancrSearch) || '[]')
}
export function setAdvancrSearch (val) {
  sessionStorage.setItem(advancrSearch, JSON.stringify(val))
}
export function removeAdvancrSearch () {
  sessionStorage.removeItem(advancrSearch)
}

const msgMarkTime = 'MarkTime'
export function getMarkTime () {
  const defaultMarkTime = JSON.stringify({
    feedback: 0,
    team: 0,
    update: 0,
    activity: 0
  })
  return JSON.parse(localStorage.getItem(msgMarkTime) || defaultMarkTime)
}
export function setMarkTime (options) {
  const key = isDataType(options, 'object') ? options.key : options
  const markTime = getMarkTime()
  markTime[key] = options.time || new Date().getTime()
  localStorage.setItem(msgMarkTime, JSON.stringify(markTime))
}
