<template functional>
  <el-empty :image-size="props.size" :class="{'card':props.isBg}">
    <template #description>
      <span>{{props.content}}</span>
    </template>
  </el-empty>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 120
    },
    content: {
      type: String,
      default: '暂无数据'
    },
    isBg: {
      type: Boolean,
      default: true
    }
  }
}
</script>
