<template>
  <div ref="bgBox" class="my_dialog_box"
       :style="`background:${isBg ? bg : 'transparent'};z-index:${zIndex}`" v-show="isShow"
       @click="maskCloseDialog">
    <div @click.stop ref="contentBox" class="dialog_boby"
         :style="`width:${width};height:${height};transform-origin: ${origin.x} ${origin.y};left: ${position.x};top:${position.y}`">
      <!-- left: ${position.x};top:${position.y} -->
      <!-- 标题区 -->
      <slot name="title" v-if="isTitle">
        <div class="title_box" :class="{ 'title_box_style': title }">
          <slot name="titleContent">
            <h3 class="title_text">
              <span v-if="title">{{ title }}</span>
            </h3>
          </slot>
          <!-- <i class="hand close_icon" @click="closeDialog">✕</i> -->
          <i class="el-icon-close close_icon" @click="closeDialog"> </i>
        </div>
      </slot>

      <!-- 内容区 -->
      <slot> </slot>
      <div v-if="isMask" class="mask"></div>
    </div>
  </div>
</template>
<script>
import { isNumber } from '@/utils/index'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '600px'
    },
    height: {
      type: String,
      default: '500px'
    },
    // 遮罩背景色
    bg: {
      type: String,
      default: 'rgba(0, 0, 0, 0.29)'
    },
    // 是否需要遮罩
    isBg: {
      type: Boolean,
      default: true
    },
    // 缩放中心点
    origin: {
      type: Object,
      default: () => ({ x: '0%', y: '0%' })
    },
    // 开始位置
    position: {
      type: Object,
      default: () => ({ x: '50%', y: '50%' })
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    isTitle: {
      type: Boolean,
      default: true
    },
    // 点击遮罩是否关闭
    maskClose: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: String,
      default: '2000'
    }
  },
  data () {
    return {
      isMask: true
    }
  },
  methods: {
    maskCloseDialog () {
      this.$emit('maskClick')
      if (this.maskClose) {
        this.closeDialog()
      }
    },
    handleClose () {
      this.$emit('update:isShow', false)
      this.$emit('handleClose')
    },
    disableScroll (type = true) {
      const body = document.querySelector('body')
      const title = document.querySelector('.home_fixed')
      if (type) {
        body.style.overflow = 'hidden'
        body.style.paddingRight = '8px'
        if (title) {
          title.style.paddingRight = '8px'
        }
      } else {
        body.style.paddingRight = '0px'
        body.style.overflow = 'auto'
        if (title) {
          title.style.paddingRight = '0px'
        }
      }
    },
    openDialog () {
      this.disableScroll()
      this.$nextTick(() => {
        const contentBox = this.$refs.contentBox
        const bgBox = this.$refs.bgBox
        contentBox.style.left = isNumber(this.position.x) ? this.position.x + 'px' : '50%'
        contentBox.style.top = isNumber(this.position.y) ? this.position.y + 'px' : '50%'
        setTimeout(() => {
          bgBox.style.opacity = '1'
          contentBox.style.left = '50%'
          contentBox.style.top = '50vh'
          contentBox.style.transform = 'scale(1) translate(-50%,-50%)'
          contentBox.style.opacity = '1'
          setTimeout(() => {
            this.isMask = false
          }, 200)
        }, 100)
      })
    },
    closeDialog () {
      const contentBox = this.$refs.contentBox
      const bgBox = this.$refs.bgBox
      this.isMask = true
      contentBox.style.left = isNumber(this.position.x) ? this.position.x + 'px' : '50%'
      contentBox.style.top = isNumber(this.position.y) ? this.position.y + 'px' : '50%'
      contentBox.style.transform = 'scale(0.1)  translate(-50%, -50%)'
      contentBox.style.opacity = '0'
      bgBox.style.transition = 'opacity 0.27s'
      bgBox.style.opacity = '0'
      this.disableScroll(false)
      setTimeout(() => {
        this.handleClose()
      }, 400)
    }
  },
  watch: {
    isShow: {
      handler (newVal) {
        if (newVal) {
          this.openDialog()
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.my_dialog_box {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 2000;
  width: 100%;
  height: 100vh;
  opacity: 0;
}

.dialog_boby {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  background-color: #fff;
  border-radius: 6px;
  transform: scale(0.1) translate(-50%, -50%);
  transition: all 0.27s;
  overflow: auto;

  .title_box {
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-template-rows: 35px;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
    padding-left: 20px;

    .title_text {
      font-size: 18px;
      font-weight: 500;
    }

    .close_icon {
      cursor: pointer;
      width: 20px;
      font-size: 20px;
    }
  }

  .title_box_style {
    border-bottom-width: 1px;
    grid-template-rows: 45px;
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
    z-index: 99;
  }
}
</style>
