<template>
  <div class="enterprise_box">
    <MyTab text="选择身份" />
    <div class="content" v-loading="loading">
      <div class="enterpr_list">
        <div class="enterpr_item bold hand" v-for="item in list" :key="item.enterpriseId"
             @click="enterpriseLogin(item)">
          <span>{{item.enterpriseName}}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyTab from './tab.vue'
import { loginByEnterpriseId } from '@/api/user'
import { mapMutations } from 'vuex'
export default {
  components: {
    MyTab
  },
  props: {},
  data () {
    return {
      list: [],
      loading: false
    }
  },
  methods: {
    ...mapMutations('user', ['updateToken']),
    async enterpriseLogin (item) {
      this.loading = true
      try {
        const res = await loginByEnterpriseId({
          phone: item.phone,
          verifyCode: item.verifyCode ? item.verifyCode : item.password,
          enterpriseId: item.enterpriseId
          // channel: 'pc'
        })
        this.updateToken(res.data)
        this.$closeLogin()
        window.location.reload()
      } catch (error) {
        this.loading = false
        this.$myNotify.error(error || '操作失败')
        this.$emit('resetLogin', 'ShortMsg')
      }
    },
    open (list) {
      this.list = list
    }
  },
  created () {
    this.open()
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 0 20px;
}
.enterpr_list {
  width: 100%;
  height: 300px;
  padding: 20px;
  // background-color: #f5f7fb;
  border-radius: 10px;
  overflow-y: auto;
  .enterpr_item {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-rows: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #f5f7fb;
    padding: 0 20px;
    margin-bottom: 20px;
    color: #000;
    font-size: 14px;
    &:hover {
      color: #4b639f;
    }
  }
}
</style>
