<template>
  <div class="edge_container">
    <div class="back_home edge_item">
      <img class="back_home_icon hand" @click="backHome"
           src="https://img.infox-med.com/webImg/infoPc/public/edgeBackHomeIcon.png" alt="">
    </div>

    <div class="code_container">
      <img class="edge_code_img" :class="{'heid_edge_code_img':!isShow}" :src="edgeUrl" alt="">
      <div class="code_icon_box" v-show="!isShow">
        <div class="edig_icon edge_item">
          <img class="download_icon"
               src="https://img.infox-med.com/webImg/infoPc/public/edgeLogoIcon.png" alt="">
        </div>
        <img class="edge_hover_img" :src="edgeUrl" alt="">
      </div>
    </div>

    <div class="back_to_top edge_item" v-if="isShowBackTop">
      <img class="back_top_icon hand" @click="backTop"
           src="https://img.infox-med.com/webImg/infoPc/public/edgeToTop.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data () {
    return {
      isShow: false,
      isShowBackTop: false,
      edgeUrl: 'https://img.infox-med.com/webImg/infoPc/public/edgeCodeimg.png'
    }
  },
  methods: {
    backHome () {
      if (this.$route.path === '/') return
      this.$router.push('/')
    },
    backTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    countdown () {
      setTimeout(() => {
        this.isShow = false
        sessionStorage.setItem('showEdge', '1')
      }, 10000)
    },
    updateScrollTop () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.isShowBackTop = scrollTop > 300
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScrollTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.updateScrollTop)
  },
  created () {
    this.isShow = sessionStorage.getItem('showEdge') !== '1'
    this.countdown()
  }
}
</script>

<style lang="less" scoped>
.edge_container {
  position: fixed;
  right: 10px;
  bottom: 210px;
  z-index: 990;
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 8px;

  .code_icon_box {
    // position: relative;
  }
  .edge_item {
    width: 52px;
    height: 52px;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    z-index: 99;
    box-shadow: 0px 0px 4px 0px rgba(98, 109, 133, 0.4);
  }
  .edig_icon {
    position: relative;

    &:hover ~ .edge_hover_img {
      transform: scale(1) translate(0, 50%);
    }
    .download_icon {
      position: relative;
      z-index: 99;
      width: 30px;
    }
  }

  .edge_hover_img {
    position: absolute;
    right: 60px;
    bottom: 0;
    width: 100px;
    transform: scale(0) translate(0, 50%);
    transform-origin: 120% 92%;
    transition: transform 0.3s;
  }
}
.code_container {
  position: relative;
  .edge_code_img {
    position: absolute;
    right: 0px;
    bottom: 3px;
    z-index: 990;
    width: 90px;
    transform: scale(1);
    transform-origin: 84% 89%;
    transition: transform 0.5s;
    z-index: 99;
  }
  .heid_edge_code_img {
    transform-origin: 80% 100%;
    transform: scale(0);
  }
}
.back_to_top {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0, 60px);
  margin-top: 10px;
  .back_top_icon {
    width: 24px;
  }
}
.back_home {
  .back_home_icon {
    width: 24px;
  }
}
</style>
