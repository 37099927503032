<template>
  <div class="user_form_container">
    <span class="identity">当前身份：{{identityName}}</span>
    <el-form ref="form" :model="form" label-width="90px" size="medium">
      <el-form-item v-if="formKey.includes('hospital')" label="医院" prop="hospital"
                    :rules="{required: true, message:'请选择所属医院', trigger: 'change' }">
        <!-- <el-input v-if="!['1','2','3','4'].includes(identityType)" v-model="form.hospital"
                  placeholder="请输入单位名称"></el-input> -->
        <Hospital v-model="form.hospital" />
      </el-form-item>

      <el-form-item v-if="formKey.includes('company')" label="单位" prop="company"
                    :rules="{required: true, message: '请输入单位名称', trigger: 'change' }">
        <el-input v-model="form.company" placeholder="请输入单位名称"></el-input>
      </el-form-item>

      <el-form-item v-if="formKey.includes('departmentId')" label="科室" prop="departmentId"
                    :rules="{required: true, message: '请选择所在科室', trigger: 'change' }">
        <Department v-model="form.departmentId" />
      </el-form-item>
      <el-form-item v-if="formKey.includes('title')" label="职称" prop="title"
                    :rules="{required: true, message: '请选择职称', trigger: 'change' }">
        <UserTitle v-model="form.title" :identityType="identityType" />
      </el-form-item>

      <el-form-item v-if="formKey.includes('school')" label="学校" prop="school"
                    :rules="{required: true, message: '请选择学校', trigger: 'change' }">
        <School v-model="form.school" />
      </el-form-item>
      <el-form-item v-if="formKey.includes('major')" label="专业" prop="major"
                    :rules="{required: true, message: '请选择专业', trigger: 'change' }">
        <Major v-model="form.major" />
      </el-form-item>
      <el-form-item v-if="formKey.includes('education')" label="学历" prop="education"
                    :rules="{required: true, message: '请选择学历', trigger: 'change' }">
        <Education v-model="form.education" />
      </el-form-item>

      <el-form-item label="姓名" prop="trueName"
                    :rules="{required: true, message: '请输入姓名', trigger: 'blur' }">
        <el-input v-model="form.trueName" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item v-if="formKey.includes('skilledField')" :label="fieldTitle.title"
                    prop="skilledField"
                    :rules="{required: true, message:`请输入${fieldTitle.title}` , trigger: 'blur' }">
        <el-input v-model="form.skilledField" :placeholder="fieldTitle.placeholder"></el-input>
      </el-form-item>

    </el-form>
    <div class="form_options flc no_select">
      <span class="reset_btn hand" @click="toSelectIdentity">&lt; 切换身份</span>
      <span class="validate_submit flc hand" @click="validateForm">确定</span>
    </div>
  </div>
</template>

<script>
import Hospital from './hospital.vue'
import Department from './department.vue'
import UserTitle from './userTitle.vue'
import School from './school.vue'
import Major from './major.vue'
import Education from './education.vue'
import user from '@/api/userInfo.js'
import { identityList } from '../map'
export default {
  props: {
    identityType: {
      type: String,
      default: ''
    },
    formKey: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Hospital,
    Department,
    UserTitle,
    School,
    Major,
    Education
  },
  data () {
    return {

      form: {
        hospital: '',
        departmentId: '',
        trueName: '',
        title: '',
        skilledField: '',
        school: '',
        major: '',
        education: ''
      }
    }
  },
  methods: {
    async submitUserInfo () {
      try {
        const data = this.formKey.reduce((res, key) => {
          if (key === 'departmentId') {
            if (this.form.departmentId !== this.form.departmentName) {
              res[key] = this.form[key]
            }
          } else {
            res[key] = this.form[key]
          }
          return res
        }, {})
        await user.updateUserInfo({
          ...data,
          identityType: this.identityType
        })
        this.$store.dispatch('user/actionsGetUserInfo', this.$store.getters.token)
        this.$myMessage.success('修改成功')
        this.$emit('closeDialog')
      } catch (error) {
        this.$myMessage.error(error || '修改失败')
        this.$emit('closeDialog')
      }
    },
    toSelectIdentity () {
      this.$emit('resetIdentity')
    },
    validateForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitUserInfo()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
  computed: {
    identityName () {
      const find = identityList.find(item => item.val === this.identityType)
      return find ? find.label : ''
    },
    fieldTitle () {
      const maps = {
        5: {
          title: '研究领域',
          placeholder: '请输入研究方向'
        },
        6: {
          title: '教学领域',
          placeholder: '请输入教学方向'
        },
        default: {
          title: '擅长领域',
          placeholder: '例：甲状腺疾病的诊断'
        }
      }
      return maps[this.identityType] || maps.default
    }
    // formKey () {
    //   switch (true) {
    //     case ['5', '6'].includes(this.identityType):
    //       return ['trueName', 'hospital', 'title', 'skilledField']
    //     case this.identityType === '7':
    //       return ['trueName', 'school', 'major', 'education']
    //     case this.identityType === '8':
    //       return ['trueName', 'hospital']
    //     default:
    //       return ['trueName', 'hospital', 'departmentId', 'title', 'skilledField']
    //   }
    // }
  },
  created () {
    const userInfo = this.$store.getters.userInfo
    if (this.$store.getters.userId && userInfo.identityType + '' === this.identityType) {
      this.form = {
        ...userInfo,
        departmentId: userInfo.departmentName
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user_form_container {
  position: relative;
  color: #000;
}
.identity {
  position: absolute;
  top: -35px;
  // left: -50px;
  right: -60px;
  color: #666;
}
/deep/.el-input__inner {
  background: #f5f7fb;
  border-radius: 28px;
  color: #000;
}
.el-form-item {
  display: grid;
  grid-template-columns: 0 auto 1fr;
  column-gap: 20px;
  margin-bottom: 15px;
  /deep/.el-form-item__label {
    font-size: 16px;
    font-weight: 600;
    padding-right: 0;
    width: auto !important;
    color: #2763c1;
  }
  /deep/ .el-form-item__error {
    padding-top: 0;
    left: 15px;
  }
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.form_options {
  position: relative;
  padding-top: 10px;
  .reset_btn {
    position: absolute;
    left: 0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    &:hover {
      color: #4b639f;
    }
  }
  .validate_submit {
    width: 188px;
    height: 37px;
    background: #f5f7fb;
    border-radius: 28px;
    font-size: 18px;
    font-weight: 600;
    color: #666666;
    &:hover {
      color: #fff;
      background: linear-gradient(90deg, #03d7ff 0%, #7f25fc 100%);
    }
  }
}
</style>
