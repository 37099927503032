<template>
  <div class="hospital_container fs14">
    <el-select :value="value" @input="input" filterable placeholder="请选择学历"
               popper-class="userinfo_dialog_select">
      <el-option v-for="item in list" :key="item" :label="item" :value="item">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      page: {
        pageNum: 1,
        pageSize: 20
      },
      list: ['高职', '大专', '本科', '硕士', '博士', '博士后']
    }
  },
  methods: {
    input (val) {
      this.$emit('input', val)
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.hospital_container {
  position: relative;
  height: 100%;
}
.el-select {
  width: 100%;

  /deep/.el-input__inner {
    background: #f5f7fb;
    border-radius: 28px;
  }
}
</style>
