import request from '@/utils/request.js'

export const setApi = (pathMap, type = 1) => {
  const requestServe = request
  return Object.keys(pathMap).reduce((res, apiName) => {
    const isGet = apiName.indexOf('get') !== -1
    res[apiName] = (options) => requestServe({
      method: isGet ? 'GET' : 'POST',
      url: pathMap[apiName],
      data: isGet ? {} : options,
      params: isGet ? options : {},
      headers: options?.headers || {}
    })
    return res
  }, {})
}
