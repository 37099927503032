import { setApi } from './index'
const paths = {
  searchHospital: 'system/searchLibHospital',
  searchDepartment: 'system/searchDepartment',
  searchSchool: 'system/searchLibSchool',
  majorList: 'system/getLibMajorList',
  updateUserInfo: '/user/changeProfiles'
}

const user = setApi(paths)
export default user
