<template>
  <div class="qr_code_comtent no_select display_flex flex_direction alignItems_center">
    <div class="img_box mb20">
      <img class="qr_code_img" :src="qrCodeUrl" alt="">
      <div v-if="isOverdue" class="overdue_box display_flex_center flex_direction">
        <span>二维码已失效</span>
        <div @click="init" class="refresh_btn hand">
          <i class="el-icon-refresh-right"></i>
          <span>点击刷新</span>
        </div>
      </div>
      <!-- isOverdue -->
    </div>
    <p class="bold fs14 mb20">打开
      <span class="company hand">
        Info X Med APP
        <div class="download_qrcode display_flex_center flex_direction">
          <img class="downicon" src="https://img.infox-med.com/webImg/infoPc/downloadAppCode.png"
               alt="">
          <div>IOS、Android下载</div>
        </div>
      </span>
      扫码登录
    </p>
    <p class="tc_6 bold fs14 hand" @click="toRegister">快速注册></p>
  </div>
</template>

<script>
import { uuid } from '@/utils/index.js'
import { gainStatus } from '@/api/user.js'
import { mapMutations } from 'vuex'
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      qrCodeUrl: '',
      timeId: null,
      isOverdue: false
    }
  },
  methods: {
    ...mapMutations('user', ['updateToken', 'removeToken']),
    reset () {
      this.$message.success('刷新')
    },
    async getLoginState (uid) {
      try {
        const res = await gainStatus(uid)
        if (res.code === 101) {
          this.isOverdue = true
          clearInterval(this.timeId)
          this.removeToken()
        } else if (res.code === 102) {
          this.updateToken(res.data)
          this.$message.success('登录成功')
          clearInterval(this.timeId)
          this.$myCount({
            name: 'PC-扫码登录',
            successCallback: () => {
              window.location.reload()
            }
          })
        }
      } catch (error) {
        console.log(error)
        clearInterval(this.timeId)
      }
    },
    getQrCode () {
      this.isOverdue = false
      const uid = uuid().replace(/-/g, '')
      this.qrCodeUrl = `https://api.infox-med.com/user/getLoginQrcode?uuid=${uid}`
      this.timeId = setInterval(() => {
        this.getLoginState(uid)
      }, 2000)
    },
    toRegister () {
      this.close()
      this.$emit('toRegister')
    },
    close () {
      this.qrCodeUrl = ''
      if (this.timeId) {
        clearInterval(this.timeId)
      }
    },
    init () {
      this.getQrCode()
    }
  },
  beforeDestroy () {
    this.close()
  },

  created () {
    if (this.isLogin) {
      this.getQrCode()
    }
  }
}
</script>

<style lang="less" scoped>
.qr_code_comtent {
  padding-top: 35px;
  .img_box {
    position: relative;
    width: 150px;
    height: 150px;
    .qr_code_img {
      width: 100%;
      height: 100%;
    }
    .overdue_box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      font-size: 14px;
      .refresh_btn {
        padding: 3px 10px;
        background-color: #fff;
        border-radius: 4px;
        color: #000;
        margin-top: 10px;
      }
    }
  }
  .company {
    position: relative;
    color: #4b639f;
    &:hover {
      .download_qrcode {
        // display: block;
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  .download_qrcode {
    // display: none;
    position: absolute;
    width: 189px;
    height: 186px;
    top: -100px;
    left: 180px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    opacity: 0;
    font-size: 12px;
    color: #666;
    transform: scale(0);
    transform-origin: -100px 60%;
    transition: all 0.3s;
    &::after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-100%, 15%);
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: #4b639f;
    }
  }
}
</style>
