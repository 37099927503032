<template>
  <div class="myRefer">
    <!-- 1. 历史搜索 -->
    <div class="history ">
      <div class="common">
        <div class="historyTitle">历史搜索</div>
        <img @click="clearImg" class="qkImg" src="@/assets/img/index/ljt.png">
      </div>
      <div class="contList">
        <template v-if="inputList.length !== 0">
          <p v-for="(item,index) in inputList" @click="gosearch(item)" :key="index"
             class="historyCont">{{item}}</p>
        </template>
        <p v-else class="noCont">暂无搜索记录</p>
      </div>
    </div>
    <!-- 2. 最近阅读 -->
    <div class="latelyRead ">
      <div class="common">
        <div class="historyTitle">最近阅读</div>
        <div class="load" @click="chakan">查看更多>></div>
      </div>
      <div class="readCont publicCont" @click="goDetails(item)" v-for="(item,index) in list"
           :key="index">
        <!-- 最近阅读 标题  超出两行默认显示... 最多五条数据  没有显示最近暂无阅读-->
        {{item.docTitle}}
      </div>
      <p v-show="latelyList.length === 0" class="noCont">最近暂无阅读记录</p>
    </div>
    <!-- 3. 我的收藏 -->
    <div class="myCollect">
      <div class="common">
        <div class="historyTitle">我的收藏</div>
        <div class="load" @click="genguo">查看更多>></div>
      </div>
      <div v-show="collectList.length !== 0" class="collectCont publicCont"
           v-for="(item,index) in collectList" @click="goDetails(item)" :key="index">
        <!-- 显示我的收藏 标题  超出两行默认显示... 最多五条数据  没有显示暂无收藏  -->
        {{item.docTitle}}
      </div>
      <p v-show="collectList.length === 0" class="noCont">快去收藏喜欢的文献吧</p>
    </div>
  </div>
</template>
<script>
import { pageTurn } from '@/utils/index.js'
import { getHistory, removeHistory, getMyRead } from '@/utils/storage.js'
import { mapGetters } from 'vuex'
import { getCollection } from '@/api/user.js'
export default {
  data () {
    return {
      collectList: [], // 保存我的收藏
      inputList: JSON.parse(getHistory() || '[]'), // 保存 搜索历史记录
      latelyList: JSON.parse(getMyRead() || '[]') // 保存最近阅读记录
    }
  },
  methods: {
    // 最近阅读跳转到文章详情页并传id
    goDetails (item) {
      this.$router.push({
        path: '/articleDetails',
        query: {
          id: item.id
        }
      })
    },
    // 清空搜索记录
    clearImg () {
      this.inputList = []
      removeHistory()
    },
    // 查看更多阅读历史
    chakan () {
      pageTurn('userInfo', '/userInfo/read')
      localStorage.setItem('path', '/userInfo/read')
    },
    // 查看更多用户收藏
    genguo () {
      pageTurn('userInfo', '/userInfo/collect')
      localStorage.setItem('path', '/userInfo/collect')
    },
    // 获取用户的收藏信息
    async collection () {
      if (this.userInfo.userId) {
        const res = await getCollection({
          category: 1,
          userId: this.userInfo.userId
        })
        this.collectList = res.data.splice(0, 5)
      }
    },
    // 点击历史搜索跳转到搜索页面
    gosearch (item) {
      this.$router.push({ path: '/searchList', query: { val: item } })
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    // 计算最多显示五条历史浏览数据
    list () {
      if (this.latelyList.length > 5) {
        return this.latelyList.slice(0, 5)
      } else {
        return this.latelyList
      }
    }
  },
  created () {
    // 获取用户收藏文章
    this.collection()
  }
}
</script>
<style lang="less" scoped>
.myRefer {
  // 历史搜索
  .common {
    display: flex;
    margin: 20px 0;
    align-items: center;
    justify-content: space-between;
    .historyTitle {
      font-size: 18px;
      color: #000;
    }
    .load {
      font-size: 14px;
      color: #6b6b6b;
    }
    img {
      width: 26px;
      height: 26px;
    }
  }
  .noCont {
    // margin: 5px auto;
    color: #6b6b6b;
    // text-align: center;
  }
  .history {
    .contList {
      display: flex;
      flex-wrap: wrap;
      .historyCont {
        // width: 47px;
        max-width: 94%;
        height: 23px;
        margin: 5px;
        text-align: center;
        font-size: 13px;
        padding: 4px 8px;
        background-color: #ececec;
        color: #6b6b6b;
        border-radius: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .publicCont {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #4b639f;
    margin: 12px 0;
    font-size: 18px;
  }
  .historyCont:hover,
  .qkImg:hover,
  .load:hover,
  .publicCont:hover {
    cursor: pointer;
  }
  .publicCont:hover {
    // color: #4d8055;
    text-decoration: underline;
  }
  .myCollect {
    padding: 0 0 20px 0;
  }
}
</style>
