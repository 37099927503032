export const msgModuels = [
  {
    name: 'Info 团队',
    category: '1',
    markName: 'team',
    list: []
  }, {
    name: '消息反馈',
    category: '2',
    markName: 'feedback'
  }, {
    name: '数据库更新',
    category: '3',
    markName: 'update',
    list: []
  }, {
    name: '活动福利',
    category: '4',
    markName: 'activity',
    list: []
  }, {
    name: '资讯栏',
    category: '5',
    markName: '',
    divided: true,
    icon: 'icon-peizhitubiaosvg-',
    list: []
  }
]
