<template>
  <div class="editPassword_content">
    <MyTab text="忘记密码" />
    <div class="edit_content">
      <MyForm :form="form" @phoneBlur="phoneBlur" />
      <p class="warning_text">密码由8-12位字母、数字组成，不能是纯数字/字母组成</p>
      <div class="login_btn_box mb20">
        <el-button :disabled="!isSubmitUpdataPassword" class="login_btn" type="primary"
                   @click="updataPassword">确 定
        </el-button>
      </div>
    </div>

    <p class="tc_6 bold fs14 hand tac" @click="toLogin">{{isRegister?'快速注册':'快速登录'}}></p>

  </div>
</template>

<script>
import MyTab from './tab.vue'
import MyForm from './loginForm.vue'
import { isRegister, updataPassword } from '@/api/user'
export default {
  components: {
    MyForm,
    MyTab
  },
  props: {

  },
  data () {
    return {
      form: {
        phone: '',
        phoneError: '',
        verifyCode: '',
        verifyCodeError: '',
        password: '',
        passwordError: '',
        confirmPassword: '',
        confirmPasswordError: ''
      },
      isRegister: false
    }
  },
  methods: {
    async phoneBlur () {
      try {
        this.form.phoneError = ' '
        const res = await isRegister({
          phone: this.form.phone
        })
        this.isRegister = !res.data
        if (res.data) this.form.phoneError = ''
        else this.form.phoneError = '该手机号未注册, 请先注册'
      } catch (error) {
        this.$myNotify.warning(error || '服务错误')
      }
    },
    async updataPassword () {
      try {
        if (!this.isSubmitUpdataPassword) return
        const res = await updataPassword({
          phone: this.form.phone,
          passWord: this.form.password,
          verifyCode: this.form.verifyCode
        })
        this.$myNotify.success('密码修改成功, 请登录')
        this.toLogin()
        console.log(this.isSubmitUpdataPassword, res)
      } catch (error) {
        this.$myNotify.warning(error || '服务错误')
      }
    },
    toLogin () {
      if (this.isRegister) {
        this.$emit('toRegister')
      } else {
        this.$emit('toLogin', 'Password')
      }
    }
  },
  computed: {
    isSubmitUpdataPassword () {
      return this.form.phone && this.form.verifyCode && this.form.password && this.form.confirmPassword && !this.form.phoneError && !this.form.verifyCodeError && !this.form.passwordError && !this.form.confirmPasswordError
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.edit_content {
  padding: 0 50px;
}
.warning_text {
  color: #979797;
  margin-bottom: 10px;
}
.login_btn_box {
  width: 100%;
  height: 40px;
  .login_btn {
    width: 100%;
    height: 100%;
  }
}
</style>
