import { msgModuels } from '@/utils/dataMap'
import { getFeedbackList, getMsgList } from '@/api/feedback.js'
import { getMarkTime } from '@/utils/storage'
export default {
  namespaced: true,
  state: () => ({
    msgModuels: msgModuels.map(item => ({ ...item, isMark: false }))
  }),

  mutations: {
    updateMark (state, config) {
      const find = state.msgModuels.find(item => item.category === config.category)
      if (find) find.isMark = config.type
    },
    updataMsgModuels (state, msgModuels) {
      state.msgModuels = msgModuels
    }
  },
  actions: {
    async initMark ({ commit, state }) {
      const res = await getFeedbackList({
        pageNum: 1,
        pageSize: 1
      })
      const markTime = getMarkTime()
      if (markTime.feedback < res.data?.[0]?.modifiedTime) {
        commit('updateMark', {
          path: '/msgContent/feedback',
          type: true
        })
      }
      const msgList = await getMsgList()
      if (!msgList.data) return
      Object.keys(msgList.data).forEach(moduleName => {
        const find = state.msgModuels.find(msg => msg.name === moduleName)
        if (!find) return
        find.list = msgList.data[moduleName] || []
        find.isMark = markTime[find.markName] < msgList.data[moduleName]?.[0]?.modifiedTime
      })
    }
  }
}
