import request from '../utils/request.js'
/**
 * 用户相关接口
 */

export function getUserInfo (data) {
  return request({
    url: 'user/getUserInfo',
    method: 'POST',
    data
  })
}

// 判断登录
export function gainStatus (uuid) {
  return request({
    url: '/user/getQrcodeStatus',
    method: 'POST',
    data: {
      uuid
    },
    headers: {
      hideLoading: true
    }
  })
}

// 获取用户详细信息
export function gainUserInfo (data) {
  return request({
    url: 'user/getUserInfo',
    method: 'POST',
    data
  })
}

// 获取用户收藏文献
export function getCollection (data) {
  return request({
    url: '/system/getCollectList',
    method: 'POST',
    data
  })
}

// 修改用户头像
export function setHeadPortrait (data) {
  return request({
    url: '/user/changeHeader',
    method: 'POST',
    data
  })
}

// 获取用户科室列表
export function getDepartmentList (data) {
  return request({
    url: '/user/getDepartmentList2',
    method: 'POST',
    data
  })
}

// 修改手机号之前先获取 验证码
export function amendVerificationCode (data) {
  return request({
    url: '/user/sendVerifyCode',
    method: 'POST',
    data
  })
}

// 修改用户昵称
export function amendName (data) {
  return request({
    url: '/user/changeName',
    method: 'POST',
    data
  })
}

// 修改用户性别
export function amendSex (data) {
  return request({
    url: '/user/changeSex',
    method: 'POST',
    data
  })
}

// 修改 单位 科室  出生日期 职称 真实姓名
export function amendProfile (data) {
  return request({
    url: '/user/changeProfile',
    method: 'POST',
    data
  })
}

// 获取用户下载文献列表
export function getDownloadRecords (data) {
  return request({
    url: '/user/getDownloadRecords',
    method: 'POST',
    data
  })
}

// 获取用户收藏的文件夹列表
export function getFolderList (data) {
  return request({
    url: '/system/collect/getFolderList',
    method: 'POST',
    data
  })
}

// 新增文件夹
export function addFolder (data) {
  return request({
    url: '/system/collect/addFolder',
    method: 'POST',
    data
  })
}

// 收藏文件到文件夹
export function addCollectToFolder (data) {
  return request({
    url: '/system/collect/addCollectToFolder',
    method: 'POST',
    data
  })
}

// 修改文件夹名称
export function editFolderName (data) {
  return request({
    url: '/system/collect/renameFolder',
    method: 'POST',
    data
  })
}

// 删除文件夹
export function deleteFolder (data) {
  return request({
    url: '/system/collect/deleteFolder',
    method: 'POST',
    data
  })
}

// 根据文件夹名字和类别获取文献列表
export function getFolderCollectList (data) {
  return request({
    url: 'system/collect/getFolderCollectList',
    method: 'POST',
    data
  })
}

// 移动文献到文件夹
export function changeFolder (data) {
  return request({
    url: '/system/collect/changeFolder',
    method: 'POST',
    data
  })
}

// 获取最近阅读列表
export function getRecentRead (data) {
  return request({
    url: '/user/getRecentRead',
    data
  })
}

// 提交反馈
export function submitFeedback (data) {
  return request({
    url: '/user/feedback',
    data
  })
}

// 获取反馈通知列表
export function getFeedbackList (data) {
  return request({
    url: '/user/getMyFeedback',
    data: data || {}
  })
}

// 获取验证码
export function getiVerificationCode (data) {
  return request({
    url: '/user/sendVerifyCode',
    data
  })
}

// 验证码登录
export function verifiCodeLogin (data) {
  return request({
    url: '/user/login',
    data
  })
}

// 企业身份登录
export function loginByEnterpriseId (data) {
  return request({
    url: '/user/loginByEnterpriseId',
    data
  })
}

// 密码登录
export function loginBypassword (data) {
  return request({
    url: '/user/loginByPassword',
    data
  })
}

// 验证用户手机号是否注册过
export function isRegister (data) {
  return request({
    url: '/user/isRegister',
    data
  })
}

// 修改用户密码
export function updataPassword (data) {
  return request({
    url: '/user/resetPassword',
    data
  })
}

// 注册
export function register (data) {
  return request({
    url: '/user/registerByPassword',
    data
  })
}

// 埋点统计
export function userCount (data) {
  return request({
    url: '/system/putPageView ',
    data,
    headers: {
      hideLoading: true
    }
  })
}

// 更新最近登陆设备信息
export function updateDevice (data) {
  return request({
    url: '/user/upsertDevice',
    data
  })
}

// 验证IP
export function getPartnerIpInfo (data) {
  return request({
    url: '/system/getPartnerIpInfo',
    data
  })
}

// 用户使用时长
export function submitUserUseTime (data) {
  return request({
    url: '/system/upsertUsedTime',
    data,
    headers: {
      hideLoading: true
    }
  })
}
