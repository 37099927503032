<template>
  <div class="verifi_code tac no_select">
    <span v-if="!timeId" class="hand" @click="getverifyCode">获取验证码</span>
    <span v-else>{{timeNum}} s</span>

    <div id="geetestBox"></div>
  </div>
</template>

<script>
import '../geetest4/gt4'
import { getiVerificationCode } from '@/api/user'
export default {
  props: {
    beforeVerify: {
      type: Function,
      default: () => true
    },
    phone: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      captchaInstance: {},
      timeId: null,
      timeNum: 60
    }
  },
  methods: {
    getverifyCode () {
      if (!this.beforeVerify()) return
      this.captchaInstance.showCaptcha()
    },
    initGeetest4 () {
      window.initGeetest4({
        captchaId: 'b3b44b115ea5bab7eec6f341934b695b',
        product: 'bind'
      }, (captcha) => {
        // captcha为验证码实例
        captcha.appendTo('#geetestBox')
        this.captchaInstance = captcha
        captcha.onSuccess(() => {
          this.verifyCodeServe(captcha.getValidate())
        })
      })
    },
    async verifyCodeServe (validate) {
      try {
        await getiVerificationCode({
          phone: this.phone,
          captchaId: validate.captcha_id,
          captchaOutput: validate.captcha_output,
          genTime: validate.gen_time,
          lotNumber: validate.lot_number,
          passToken: validate.pass_token
        })
        this.countDown()
        this.$myNotify.success('验证码已发送')
      } catch (error) {
        console.log(error)
      }
    },
    countDown () {
      this.timeId = setInterval(() => {
        this.timeNum--
        if (this.timeNum === 0) {
          clearInterval(this.timeId)
          this.timeNum = 60
          this.timeId = null
        }
      }, 1000)
    },
    init () {
      if (this.timeId) {
        clearInterval(this.timeId)
        this.timeId = null
        this.timeNum = 60
      }
    }
  },
  beforeDestroy () {
    this.init()
  },
  mounted () {
    this.initGeetest4()
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.verifi_code {
  padding-left: 10px;
  width: 120px;
  color: #4b639f;
}
</style>
