<template>
  <div class="animated" :class="{'shake': isAnimated}">
    <div class="agreement_options bold">
      <el-checkbox v-model="checked" @change="change"></el-checkbox>
      我已阅读并同意 Info X Med
      <span class="tc" @click="toXyPage('user')">《用户协议》</span>
      <span class="tc" @click="toXyPage('privacy')">《隐私政策》</span>
      和
      <span class="tc" @click="toXyPage('gx')">《服务协议》</span>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checked: false,
      openUelMap: {
        user: 'https://h5.infox-med.com/app/user.html?isTitle',
        privacy: 'https://h5.infox-med.com/app/privacy.html?isTitle',
        gx: 'https://h5.infox-med.com/app/gongXiang.html?isTitle'
      },
      isAnimated: false
    }
  },
  methods: {
    change (val) {
      this.$emit('checkChange', val)
    },
    toXyPage (key) {
      window.open(this.openUelMap[key])
    },
    animation () {
      this.isAnimated = true
      setTimeout(() => {
        this.isAnimated = false
      }, 1000)
    }
  },
  watch: {
    isLogin (newVal) {
      if (!newVal) {
        this.checked = false
      }
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.agreement_options {
  padding: 0 30px;
}
.tc {
  cursor: pointer;
  color: #4b639f;
}
</style>
