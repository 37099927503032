import lwRequest from '@/utils/lwRequest'
import fwRequest from '@/utils/fwRequest'
import request from '@/utils/request.js'
// 获取劳务列表
export function getLwVideoList (params) {
  return lwRequest({
    url: '/LaowuVideo',
    params
  })
}
// 校验服务入口权限
export function fwCheck () {
  return fwRequest({
    url: '/Laowu/checkCount'
  })
}

// 提交服务用户信息
export function submitFwUserInfo (data) {
  return fwRequest({
    url: '/Laowu/updateLaowuInfo',
    data
  })
}
// 获取name2 的服务数据列表
export function fw2DataList (data) {
  return fwRequest({
    url: '/Laowu/getLaowuInfoBypsw',
    data
  })
}
// 删除上传的服务文件
export function delFwFile (data) {
  return fwRequest({
    url: 'laowuVideoData/delete',
    data
  })
}
// 删除服务七牛云的资源
export function delFwFileInQn (filePath) {
  return request({
    url: '/system/deleteByFilePath',
    data: {
      filePath
    }
  })
}
// 上传视频
export function uploadVideo (method, config) {
  return lwRequest({
    url: '/LaowuUploadVideo',
    method: method,
    params: config.params,
    data: config.data
  })
}

// 修改任务信息
export function editLabour (id, data) {
  return lwRequest({
    url: '/Laowu/' + id,
    method: 'PUT',
    data
  })
}

// 获取视频列表
export function getVideoList (params) {
  return lwRequest({
    url: '/LaowuQueryVideo',
    params
  })
}
