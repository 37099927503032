export const identityList = [
  {
    label: '医生',
    val: '1',
    iconUrl: 'list_1.png',
    activateIconUrl: 'list_1_atv.png'
  }, {
    label: '药师',
    val: '2',
    iconUrl: 'list_2.png',
    activateIconUrl: 'list_2_atv.png'
  }, {
    label: '护士',
    val: '3',
    iconUrl: 'list_3.png',
    activateIconUrl: 'list_3_atv.png'
  }, {
    label: '技师',
    val: '4',
    iconUrl: 'list_4.png',
    activateIconUrl: 'list_4_atv.png'
  }, {
    label: '研究员',
    val: '5',
    iconUrl: 'list_5.png',
    activateIconUrl: 'list_5_atv.png'
  }, {
    label: '教学',
    val: '6',
    iconUrl: 'list_6.png',
    activateIconUrl: 'list_6_atv.png'
  }, {
    label: '学生',
    val: '7',
    iconUrl: 'list_7.png',
    activateIconUrl: 'list_7_atv.png'
  }, {
    label: '企业',
    val: '8',
    iconUrl: 'list_8.png',
    activateIconUrl: 'list_8_atv.png'
  }
]
