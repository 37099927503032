import axios from 'axios'
import store from '@/store/index'
// import router from '@/router/index.js'
// import router from '@/router/index.js'
// 导入ui提醒
import { Loading } from 'element-ui'
// const baseURL = 'http://192.168.1.160:5001'
const baseURL = 'https://enterprise.infox-med.com/info'
const instance = axios.create({
  baseURL: baseURL,
  // 请求超过这么多时间还没有返回就报错
  timeout: 10000 * 60 // request timeout
})
// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading
function startLoading () {
  loading = Loading.service({
    lock: true,
    text: '正在加载，请耐心等待...',
    target: document.querySelector('.loading') // 设置加载动画区域
  })
}
function endLoading () {
  loading.close()
}
// 声明一个对象用于存储请求个数
let needLoadingRequestCount = 0
function showFullScreenLoading () {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}
function tryHideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

// 请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (!['/Laowu/checkCount'].includes(config.url)) {
    showFullScreenLoading()
  }
  if (store.getters.token) {
    // config.params = { 'token': store.getters.token } // 如果要求携带在参数中
    config.headers.token = store.getters.token // 如果要求携带在请求头中
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})
// // 响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  setTimeout(() => {
    tryHideFullScreenLoading()
  }, 150)
  // 用户权限过期
  if (response.data.code === 401 && response.data.msg === '无效的登录信息') {
    store.commit('user/removeToken')
    return Promise.reject(response.data.msg)
    // Message.warning('登录过期请重新登录')
  }
  if (response.data.code === 200) {
    return response.data
  } else {
    return Promise.reject(response.data.msg)
  }
}, function (error) {
  // 登录过期删除token和个人信息并且跳转到登录页面
  // if (error.response.response === 401) {
  //   store.commit('user/removeToken')
  //   router.push('/')
  // } else {
  //   // 不是401，也不是200，那么说明是其他错误，直接进行提示
  //   Message.error(error.response.message)
  // }
  // 对响应错误做点什么
  setTimeout(() => {
    tryHideFullScreenLoading()
  }, 150)
  return Promise.reject(error)
})
// 封装一个通用的请求方法
export default (options) => {
  return instance({
    method: options.method || 'POST',
    url: options.url,
    data: options.data,
    params: options.params,
    headers: options.headers
  })
}
