import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import login from './modules/login.js'
import heightSearch from './modules/heightSearch'
import msg from './modules/msg.js'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    login,
    heightSearch,
    msg
  },
  getters: {
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    userIpInfo: state => state.user.userIpInfo,
    userId: state => state.user.userInfo?.userId,
    isNavBg: state => state.login.isNavBg,
    msgModuels: state => state.msg.msgModuels,
    selectAdvanceSearchList: state => state.heightSearch.selectAdvanceSearchList
  }
})
