import articleList from '@/components/articleList/index.vue'
import collection from '@/components/collection/index.vue'
import placeholder from '@/components/placeholder/index.vue'
import Empty from '@/components/empty/index.vue'
import { initTime, myMessage, notify, count } from '@/utils/index.js'
export default (vue) => {
  const componentsMap = {
    Empty,
    articleList,
    collection,
    placeholder
  }
  Object.keys(componentsMap).forEach(key => vue.component(key, componentsMap[key]))

  const mapApi = {
    $initTime: initTime,
    $myMessage: myMessage,
    $myNotify: notify,
    $myCount: count
  }
  Object.keys(mapApi).forEach(key => {
    Object.defineProperty(vue.prototype, key, {
      get () {
        return mapApi[key]
      }
    })
  })
}
// this.$myCount('')

// this.$myCount({
//   name: '',
//   itemId: this.id
// })
