import { getToken, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/storage.js'
import { getOs, getCurrentBrowser, getBrowserVersion } from '@/utils/index'
import { gainUserInfo, updateDevice, getPartnerIpInfo } from '@/api/user.js'
export default {
  namespaced: true,
  state: () => ({
    token: getToken() || '',
    userInfo: JSON.parse(getUserInfo() || '{}'),
    userIpInfo: {}
    // userId: ''
  }),
  mutations: {
    updateUserIp (state, ipInfo) {
      if (!ipInfo?.partnerId) return (state.userIpInfo = {})
      state.userIpInfo = {
        ...ipInfo,
        ipName: ipInfo?.partnerName.length > 6 ? ipInfo?.partnerName.substring(0, 6) + '...' : ipInfo.partnerName
      }
    },
    // 更新token
    updateToken (state, payload) {
      const token = payload.token || ''
      state.token = token
      // state.userId = payload.userId
      setToken(token)
    },
    // 更新用户信息
    setuserInfo (state, payload) {
      state.userInfo = payload
      setUserInfo(payload)
    },
    // 删除token和用户信息
    removeToken (state) {
      state.token = ''
      state.userInfo = {}
      removeToken()
      removeUserInfo()
    }
  },
  actions: {
    async actionsGetUserInfo (context, token) {
      try {
        context.commit('updateToken', { token })
        const res = await gainUserInfo()
        res.data && context.commit('setuserInfo', res.data)
        const device = {
          deviceName: getCurrentBrowser(),
          version: getBrowserVersion(),
          systemVersion: '', // 系统版本
          deviceld: '',
          channel: getOs()
        }
        await updateDevice(device)
      } catch (error) {
      }
    },
    async getUserIpInfo (context) {
      const res = await getPartnerIpInfo()
      context.commit('updateUserIp', res.data)
      return res
    }
  }
}
