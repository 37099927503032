<template>
  <div class="hospital_container fs14">
    <el-select :value="value" @input="input" filterable placeholder="请输入专业关键词搜索"
               popper-class="userinfo_dialog_select" default-first-option>
      <el-option v-for="item in majorList" :key="item.id" :label="item.professional"
                 :value="item.professional">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import user from '@/api/userInfo.js'
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      page: {
        pageNum: 1,
        pageSize: 20
      },
      majorList: []
    }
  },
  methods: {
    input (val) {
      this.$emit('input', val)
    },
    async getList () {
      const res = await user.majorList({
        pageNum: 1,
        pageSize: 10000
      })
      this.majorList = res.data
    }
  },
  created () {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.hospital_container {
  position: relative;
  height: 100%;
}
.el-select {
  width: 100%;

  /deep/.el-input__inner {
    background: #f5f7fb;
    border-radius: 28px;
  }
}
</style>
