import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.css'
import '@/style/common.css'
import ElementUI from 'element-ui'
import '@/utils/prohibit.js'
import 'element-ui/lib/theme-chalk/index.css'
import './theme/index.css'
import './style/elStyle.css'
// import 'element-ui/lib/theme-chalk/display.css'
// 导入全局自定义插件
import plugins from '@/utils/plugins'
// 引入 动画.css
import animated from 'animate.css'
import './assets/font/iconfont.css'
// 引入复制到剪切板的插件
import VueClipboard from 'vue-clipboard2'
// 导入路由守卫
import '@/router/permission.js'

Vue.use(VueClipboard)
Vue.use(animated)
Vue.use(plugins)

Vue.use(ElementUI)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
