<template>
  <div class="container fs14">
    <el-select :value="value" @input="input" filterable remote reserve-keyword placeholder="请输入科室名称"
               :remote-method="remoteMethod" :loading="loading"
               popper-class="userinfo_dialog_select" default-first-option>
      <el-option v-for="item in list" :key="item.id" :label="item.text" :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import user from '@/api/userInfo.js'
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      list: [],
      loading: false,
      timeId: null
    }
  },
  methods: {
    removeTime () {
      if (this.timeId) {
        clearTimeout(this.timeId)
        this.timeId = null
      }
    },
    remoteMethod (query) {
      this.removeTime()
      if (query !== '') {
        this.loading = true
        this.timeId = setTimeout(() => {
          this.getList(query)
        }, 500)
      } else {
        this.list = []
      }
    },
    input (val) {
      this.$emit('input', val)
    },
    async getList (keywords) {
      const res = await user.searchDepartment({
        keywords
      })
      this.list = res.data.map(item => ({
        text: item.secondLevel,
        id: item.id.toString(),
        firstLevel: item.firstLevel
      }))
      this.loading = false
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  height: 100%;
}
.el-select {
  width: 100%;

  /deep/.el-input__inner {
    background: #f5f7fb;
    border-radius: 28px;
  }
}
</style>
