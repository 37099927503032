<template>
  <div class="">
    <MyDialog ref="userInfoDialog" :isShow.sync="show" width="808px"
              :height="isConfirmIdentity?cardHeight:'585px'" :isBg="true" :isTitle="false"
              zIndex="1001" @handleClose="handleClose" :maskClose="false" bg="rgba(0, 0, 0, 0.3)">
      <div class="container">
        <img class="container_top_bg"
             src="https://img.infox-med.com/webImg/infoPc/collectUserInfo/card_title_bg.png" alt="">
        <div class="content">
          <div class="top_title tac no_select">
            <h2 class="fs22 bold mb15">完善信息</h2>
            <p class="fs14">尊敬的Info用户：您好，为了更好的为您提供服务，需要完善下方信息~</p>
          </div>
          <div class="content_card">
            <div class="content_title flc fs18 bold no_select">
              {{isConfirmIdentity?'在下方填写您的信息':'选择您当前的身份'}}
            </div>
            <IdentityList v-if="!isConfirmIdentity" @confirmIdentity="confirmIdentity"
                          :identityType.sync="identityType" />
            <UserForm :identityType="identityType" :formKey="formKey" ref="userForm" v-else
                      @resetIdentity="resetIdentity" @closeDialog="closeDialog" />
          </div>
        </div>
      </div>
    </MyDialog>
  </div>
</template>

<script>
import MyDialog from '@/components/myDialog/newIndex.vue'
import IdentityList from './components/identityList.vue'
import UserForm from './components/userForm.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
  },
  components: {
    MyDialog,
    IdentityList,
    UserForm
  },
  data () {
    return {
      show: false,
      isConfirmIdentity: false,
      identityType: ''
    }
  },
  methods: {
    closeDialog () {
      this.show = false
    },
    resetIdentity () {
      this.isConfirmIdentity = false
    },
    confirmIdentity () {
      this.isConfirmIdentity = true
    },
    handleClose () {

    },
    initData () {
      if (this.userId) {
        if (this.userInfo.identityType) {
          this.identityType = this.userInfo.identityType + ''
          this.isConfirmIdentity = true
          const find = this.formKey.find((key) => {
            if (key === 'departmentId') {
              return !this.userInfo.departmentName
            }
            if (key === 'skilledField') return false
            return !this.userInfo[key]
          })
          if (find) { this.show = true }
          // this.show = true
        } else {
          this.show = true
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'userId']),
    formKey () {
      switch (true) {
        case ['5', '6'].includes(this.identityType):
          return ['trueName', 'hospital', 'title', 'skilledField']
        case this.identityType === '7':
          return ['trueName', 'school', 'major', 'education']
        case this.identityType === '8':
          return ['trueName', 'company']
        default:
          return ['trueName', 'hospital', 'departmentId', 'title', 'skilledField']
      }
    },
    cardHeight () {
      switch (true) {
        case ['7', '6', '5'].includes(this.identityType):
          return '552px'
        case this.identityType === '8':
          return '450px'
        case ['1', '2', '3', '4'].includes(this.identityType):
          return '605px'
        default:
          return '585px'
      }
    }
  },
  watch: {
    'userInfo' () {
      this.initData()
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  height: 100%;
}
.container_top_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
}
.content {
  height: 100%;
  position: relative;
  z-index: 99;
  color: #2763c1;
  padding: 30px 120px 60px;
}
.top_title {
  margin-bottom: 50px;
}
.content_card {
  position: relative;
  height: calc(100% - 115px);
  padding: 50px 80px 40px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(40, 101, 212, 0.26);
  border-radius: 10px;
  .content_title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 288px;
    height: 50px;
    background: #ebf5ff;
    border-radius: 28px;
  }
}
</style>
