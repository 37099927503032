<template>
  <div class="search_proposal no_select">
    <ul class="hot_search_list">
      <li class="title">热搜榜</li>
      <li @click="goSearchListPage(item.keywords)" v-for="(item, i) in HotSearchList" :key="i">
        <span class="text">{{i+1}}. {{item.keywords}}</span>
        <img v-if="i<3" class="hot_icon" src="@/assets/img/index_title/huo.png" alt="">
      </li>
    </ul>
  </div>
</template>
<script>
import { getHotSearchList } from '@/api/article.js'
// import { setHistory, getHistory } from '@/utils/storage.js'
export default {
  props: {
    inputVal: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      HotSearchList: [] // 热搜列表
    }
  },
  methods: {
    // 跳转到搜索页
    goSearchListPage (keyword) {
      this.$emit('valChange', keyword)
    },
    async getHotSearchList () {
      const res = await getHotSearchList()
      this.HotSearchList = res.data
    }
  },
  created () {
    this.getHotSearchList()
  },
  // 后期搜索建议
  watch: {
    inputVal (newInput) {
      // console.log(newInput)
    }
  }
}
</script>
<style lang="less" scoped>
.search_proposal {
  width: 100%;
  min-height: 200px;
  border-radius: 8px;
  background-color: #fff;
  color: #4b639f;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .hot_search_list {
    li {
      text-align: left;
      padding: 10px 20px;
      font-size: 14px;
      // margin-bottom: 10px;
      // border-bottom: 1px solid #e8e8e8;
      &:last-child {
        border: 0px;
      }
      &:hover {
        background-color: #f5f7fb;
      }

      .text {
        cursor: pointer;
      }
      .hot_icon {
        float: right;
        width: 18px;
        height: 18px;
      }
    }
    .title:hover {
      background-color: #fff;
    }
  }
}
</style>
