import store from '@/store/index'
import { getAdvancrSearch, removeAdvancrSearch } from '@/utils/storage.js'
export default {
  namespaced: true,
  state: () => ({
    selectAdvanceSearchList: []
  }),
  mutations: {
    // 追加筛选项
    addAdvanceSearchItem (state) {
      const item = {
        name: '',
        enName: '',
        type: 'text',
        inputVal: '',
        time: [],
        newTime: '',
        option: 'AND'
      }
      state.selectAdvanceSearchList.push(item)
    },
    // 持久化高搜
    sessionStorageAdvanceSearch (state) {
      state.selectAdvanceSearchList = getAdvancrSearch()
    },
    // 删除一项选中项
    removeAdvanceSearchItem (state, item) {
      const i = state.selectAdvanceSearchList.indexOf(item)
      if (i !== -1) {
        state.selectAdvanceSearchList.splice(i, 1)
      }
    },
    // 重置
    initSelectAdvanceSearchList (state) {
      state.selectAdvanceSearchList = []
      removeAdvancrSearch()
      for (let i = 0; i < 2; i++) {
        store.commit('heightSearch/addAdvanceSearchItem')
      }
    }
  }
}
