// 导航守卫
import router from '@/router/index'
import store from '@/store/index.js'
export const guard = ['/pdfDetails', '/userInfo/myNote', '/userInfo/collect', '/userInfo/read', '/userInfo/download', '/uploadVideo', '/feedback', '/msgContent']
// const guard = ['/pdfDetails', '/userInfo/*']
router.beforeEach((to, from, next) => {
  const token = store.getters.token
  if (token) {
    // 登陆了
    next()
  } else {
    // 没有登录
    if (guard.includes(to.path)) {
      // 访问的是黑名单的内容让你跳到首页
      next('/')
      // store.getters.
    } else if (to.path === '/videoDetails') {
      next('/infoCollege')
    }
    next()
  }
})
